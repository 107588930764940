import { Link } from 'react-router-dom';
import routes from "../../../config/routes";
import "./EHelpIcon.scss";

interface IProps {
  hash?: string;
  label: string;
}

const EHelpIcon = (props: IProps) => {
  return (
    <Link
      className="e-help-icon"
      to={routes.help.path + (props.hash ? "#" + props.hash : "")}
      aria-label={props.label}
    />
  );
};

export default EHelpIcon;
