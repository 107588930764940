import { Box, Tab, Tabs } from "@material-ui/core";
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { IMeasurementSiteConfig, IRiver, ISlugMeasurementSite } from "../../../models/config";
import { IDetailRiver } from "../../../models/misc";
import EHelpIcon from "../../elements/help-icon/EHelpIcon";
import MDetailChart from "../detail-chart/MDetailChart";
import "./MRiverAreaDetailBox.scss";

interface IProps {
    riverAreaMSSlugs: Record<string, ISlugMeasurementSite>;
    riverAreaRivers: Record<string, IRiver>;
    rivers: Record<string, IDetailRiver>;
    riverAreaSlugKey: string | null;
    measurementSites: Record<string, IMeasurementSiteConfig>
}

const MRiverAreaDetailBox = (props: IProps) => {

    const [tabValue, setTabValue] = useState(0)
    const changeActiveTab = (event, newValue) => {
        setTabValue(newValue)
    }

    const tabValues = []
    const history = useHistory();
    const locationHash = useLocation().hash;

    useEffect(()=> {
        // initializes tabvalue by comparing tabnames with locationshash
        if (locationHash.length) {
            tabValues.forEach((el) => {
                if (locationHash.slice(1) === el.name) {
                    setTabValue(el.tabValue)
                }
            })
        }
    }, [locationHash])

    const TabPanel = (props) => {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box className="m-detailbox-waterlevels__tab-box" p={3}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }
    return (
        <div className="m-riverarea-detailbox">
            <h2>Pegelstände<EHelpIcon hash={'flussgebiet-uebersicht'} label="Hinweise zur Übersicht der Flussgebiete"/></h2>

            <Box className="m-detailbox-waterlevels__navigation">
                <Tabs
                    value={tabValue}
                    onChange={changeActiveTab}
                    className="m-detailbox-waterlevels__tabs"
                    variant="scrollable"
                    scrollButtons="on"
                >
                    {props.riverAreaRivers && (
                        Object.keys(props.riverAreaRivers).map((el, key) => {
                            tabValues.push({name: props.riverAreaRivers[el]?.name, tabValue: key})
                            return <Tab
                                label={props.riverAreaRivers[el]?.name}
                                key={key}
                                className="m-detailbox-waterlevels__tab-button"
                                onClick={() => {
                                    history.push('/flussgebiet/' + props.riverAreaSlugKey + '#' + props.riverAreaRivers[el]?.name)
                                }}
                            />
                        })
                    )}
                </Tabs>
            </Box>

            {Object.keys(props.riverAreaRivers).map((riverKey, key) => {
                return (
                    <TabPanel value={tabValue} index={key} key={key}>
                        {props.riverAreaRivers[riverKey]?.measurementSites.map((msNumber, i) => {
                            const msConfig = props.measurementSites[msNumber]
                            const msData = props.rivers[riverKey]?.measurementSites[msNumber]
                            return msData && (
                                <MDetailChart
                                    riverName={props.riverAreaRivers[riverKey].name}
                                    data={msData}
                                    isRiverAreaView={true}
                                    msSlug={msConfig.slug}
                                    msName={msConfig.name}
                                    riverAreaSlug={props.riverAreaSlugKey}
                                    isSeaSite={msConfig.isSeaSite}
                                    key={i}
                                />
                            )
                        })}
                    </TabPanel>
                )
            })}
        </div>
    );
};

export default MRiverAreaDetailBox;