import './EAccordeon.scss';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import AccordeonButtonIcon from '../../../assets/images/icon-accordeon-button.svg';

interface IProps {
  externalIsOpen?: boolean; // initial isOpen state controlled from outside of this component
  children: {
    heading: ReactElement<any, any>;
    content: ReactElement<any, any>;
  };
}

const EAccordeon = (props: IProps) => {
  const [id] = useState(() => (Math.random()).toString().replace('.', ''));
  const [isOpen, setIsOpen] = useState<boolean>(true);

  useEffect(() => {
    if (props.externalIsOpen === undefined) {
      return;
    }
    setIsOpen(props.externalIsOpen);
  }, [props.externalIsOpen]);

  const handleHeadingClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
    else (setIsOpen(false))
  }

  // checks if event (click) is a link or somethin else - if its a link then set target if not then search in parent tree otherwise set undefined 
  const handleDescriptionClick = (event) => {
    event.preventDefault()
    const target = event.target.tagName === 'A' ? event.target : event.target.closest('a') ? event.target.closest('a') : undefined;

    if (target) {
      const origin = window.location.origin;
      const href = target.href;

      // check if href is an internel link of the site's origin
      if (href.startsWith(origin)) {
        window.location.href = href
      } else {
        window.open(href)
      }
    }
  }

  return (
    <div className={`e-accordeon${isOpen ? ' e-accordeon--open' : ''}`}>
      <div className="e-accordeon__heading" onClick={handleHeadingClick}>
        <div className="e-accordeon__heading-inner">
          {props.children.heading}
        </div>
        <img src={AccordeonButtonIcon} aria-hidden='true' />
      </div>
      <div className="e-accordeon__content-wrapper">
        <div className="e-accordeon__content">
          <div className="e-accordeon__content-inner" onClick={handleDescriptionClick}>
            {props.children.content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EAccordeon;
