import { Grid } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import { ReactNode, useEffect, useState } from 'react';
import { IStatusReport, IStatusReportIndex } from "../../../models";
import IConfig from "../../../models/config";
import { momentFullFormat } from '../../../service/chartDataHandler';
import globalService from "../../../service/globalService";
import "./VStatusReport.scss";

interface IProps {
}

const VStatusReport = (props: IProps) => {

    const [statusReport, setStatusReport] = useState<IStatusReportIndex | null>(null)
    const [config, setConfig] = useState<IConfig>(null)

    const getStatusReports = async () => {
        const res = await globalService.getStatusReports()
        if (res) setStatusReport(res)
    }

    const getConfig = async () => {
        const config: IConfig = await globalService.getConfig()
        if (config) setConfig(config)
    }

    useEffect(() => {
        getStatusReports()
        getConfig()
    }, [])

    interface IReportLineProps {
        statusReport: IStatusReport
        title: string
        level: string
        children?: ReactNode
    }

    const ReportLine = (props: IReportLineProps) => {
        const title = props.level === "root" ?
            <h2>{props.title}</h2> :
            props.level === 'riverarea' ?
                <h3>Flussgebiet {props.title}</h3> :
                <h3>{props.title}</h3>;

        const statusReport = props.statusReport

        return (
            <div className={'v-status-report__' + props.level}>
                <div className="v-status-report__head">
                    {title}

                    {statusReport.date &&
                        <p>vom {momentFullFormat(statusReport.date)} Uhr</p>
                    }
                </div>

                {statusReport.info &&
                    <div className="v-status-report__info" dangerouslySetInnerHTML={{__html: statusReport.info}}></div>
                }

                {props.children}
            </div>
        )
    }

    return ((statusReport && config) &&
        <div className="v-status-report container">
            <Grid container spacing={4}>
                <Grid item xs={12} md={9}>
                    <h1 className="v-status-report__headline">Hochwasserbericht</h1>
                    <p className="v-status-report__publisher">Herausgegeben von der Hochwasservorhersagezentrale am Landesamt für Umwelt in Mainz</p>

                    <ReportLine statusReport={statusReport} level={'root'} title={'Überblick zur aktuellen Situation'}/>

                    <div>
                        {statusReport.riverAreas && Object.keys(statusReport.riverAreas).map((riverAreaID, i) => {
                            const riverAreaStatusReport = statusReport.riverAreas[riverAreaID]
                            return (
                                <ReportLine statusReport={riverAreaStatusReport} level={'riverarea'} title={config.riverareas[riverAreaID].name} key={i}>
                                    {riverAreaStatusReport.rivers && Object.keys(riverAreaStatusReport.rivers).map((riverID, j) => {
                                        const riverStatusReport = riverAreaStatusReport.rivers[riverID]
                                        return (config.riverareas[riverAreaID].rivers[riverID] &&
                                            <ReportLine statusReport={riverStatusReport} level={'river'} title={config.riverareas[riverAreaID].rivers[riverID].name} key={j}/>
                                        )
                                    })}
                                </ReportLine>
                            )
                        })}
                    </div>
                    <p className="v-status-report__notice">Angaben ohne Gewähr</p>
                </Grid>

                <Grid item xs={12} md={3}>
                    <div className="l-content-box">
                        <Button variant="contained" href={'/'} className="button">Zurück zur Kartenansicht</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default VStatusReport;