import L from "leaflet";
import { throttle } from "./helpers";
import { useCallback, useRef } from "react";

const useEventForwarder = (throttleMs: number) => {

    const _layerHover = useRef<Element>(null);

    const _handleMouseMove = useCallback((event) => {
        // get the target pane
        const currentTarget = event.originalEvent.target;

        // hide the target node
        const removed = { node: currentTarget, pointerEvents: currentTarget.style.pointerEvents };
        currentTarget.style.pointerEvents = 'none';

        // attempt to grab the next layer below
        const nextTarget = document.elementFromPoint(event.originalEvent.clientX, event.originalEvent.clientY);

        // we keep drilling down until we get stopped,
        // or we reach the map container itself
        if (
            nextTarget &&
            nextTarget.nodeName.toLowerCase() !== 'body' &&
            nextTarget.classList.value.indexOf('leaflet-container') === -1
        ) {
            const isCanvas = nextTarget.nodeName.toLowerCase() === 'canvas';
            const eventType = isCanvas ? 'mousemove' : 'mouseover';
            const forwardEvent: any = new MouseEvent(eventType, event.originalEvent);

            nextTarget.dispatchEvent(forwardEvent);
            if (forwardEvent._stopped) {
                if (_layerHover.current && _layerHover.current !== nextTarget) {
                    _layerHover.current.dispatchEvent(
                        new MouseEvent('mouseout', event.originalEvent)
                    );
                }
                _layerHover.current = nextTarget;
            }
        }

        // restore pointerEvents
        removed.node.style.pointerEvents = removed.pointerEvents;
    }, []);

    const _handleClick = useCallback((event) => {
        const currentTarget = event.originalEvent.target;
        const targetStore = {node: currentTarget, pointerEvents: currentTarget.style.pointerEvents}

        currentTarget.style.pointerEvents = 'none';

        const nextTarget = document.elementFromPoint(event.originalEvent.clientX, event.originalEvent.clientY);

        if (
            nextTarget &&
            nextTarget.nodeName.toLowerCase() !== 'body' &&
            nextTarget.classList.value.indexOf('leaflet-container') === -1
        ) {
            const forwardEvent: any = new MouseEvent(event.originalEvent.type, event.originalEvent);
            let stopped = !nextTarget.dispatchEvent(forwardEvent);
            if (stopped || forwardEvent._stopped) {
                L.DomEvent.stop(event);
            }
        }

        targetStore.node.style.pointerEvents = targetStore.pointerEvents;
    }, []);

    const initEventForwarder = useCallback((map: L.Map) => {
        if (!map) {
            return;
        }

        map.on('mousemove', throttle(_handleMouseMove, throttleMs), this);
        map.on('click', _handleClick, this);

        return () => {
            map.off('mousemove', throttle(_handleMouseMove, throttleMs), this);
            map.off('click', _handleClick, this);
        }
    }, [_handleClick, _handleMouseMove, throttleMs]);

    return {
        initEventForwarder
    }
}

export default useEventForwarder;