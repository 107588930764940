import "./MMenuOverlay.scss"

interface IProps {
    isOpen: boolean
}

const MMenuOverlay = (props: IProps) => {
    return (
        <div className={"menu-overlay" + (props.isOpen ? " menu-overlay--open" : "")}/>
    )
}

export default MMenuOverlay