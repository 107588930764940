import L, { CircleMarker as LeafletCircleMarker } from 'leaflet';
import { createLayerComponent, updateMediaOverlay } from '@react-leaflet/core';

L.Canvas.include({
    _updateCustomMarker: function (layer) {
        if (!this._drawing || layer._empty()) {
            return;
        }

        let p = layer._point,
            ctx = this._ctx,
            r = Math.max(Math.round(layer._radius), 1);

        ctx.strokeStyle = layer.options.fillColor || 'black';

        r = Math.round(r - (r / 100) * 70)

        ctx.lineWidth = 1.5;

        ctx.beginPath();

        ctx.moveTo(p.x - r, p.y - r);
        ctx.lineTo(p.x, p.y + r);
        ctx.lineTo(p.x + r, p.y - r);

        ctx.stroke();
    }
});


const CustomMarker: typeof LeafletCircleMarker = LeafletCircleMarker.extend({
    _updatePath: function () {
        this._renderer._updateCustomMarker(this);
    },
});

const createLeafletElement = (props: any, context) => {
    const { center, radius, ...options } = props;
    const instance = new CustomMarker(center, {
        radius,
        ...options,
    });

    return { instance, context };
};

const updateLeafletElement = (instance, prevProps, nextProps) => {
    if (nextProps.center !== prevProps.center) {
        instance.setLatLng(nextProps.center);
    }

    if (nextProps.radius !== prevProps.radius) {
        instance.setRadius(nextProps.radius);
    }

    updateMediaOverlay(instance, prevProps, nextProps);
};

const PredictionMarker = createLayerComponent(createLeafletElement, updateLeafletElement);

export default PredictionMarker;
