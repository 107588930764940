import { GeoJSON, GeoJSONProps } from "react-leaflet"
import { unproject } from "../../../utils/mapUtils"

/**
 * Line Component Leaflet Layer
 * used to display and handle riverLines
 */
interface IProps {
    data: GeoJSONProps['data']
}

/**
 * Define Weight for River Lines
 */
const getWeight = (data) => {
    const order = data.properties.ORDER
    if (order === 1)
        return 3
    if (order === 2)
        return 1.75
    if (order >= 3)
        return .5
    return 1.75
}

const EMap__RiverPolygon = (props: IProps) => {
    /* Render river as a GeoJSON Object with weight depending on rivers order. */
    /* hide rivers of order 2 or 3 on lower zoom level */
    // if (props.data.properties.ORDER == 2 && zoom < 10) return null
    // if (props.data.properties.ORDER == 3 && zoom <= 10) return null
    const weight = getWeight(props.data)
    const opts = {
        data: props.data,
        coordsToLatLng: unproject,
        // noClip: true,
        // shadowPane: '',
        interactive: false,
        // className: 'polygon',
        style: {
            color: '#5c7ea2',
            weight: weight
        }
    }

    return <GeoJSON {...opts}/>
}

export default EMap__RiverPolygon