import { createStore, action, Action } from 'easy-peasy';
import { TSeverity } from '../models/misc';

export interface INotify {
  shown: boolean;
  message: string;
  severity: TSeverity;
}

export interface INotifyModel {
  item: INotify;
  set: Action<{}, INotify>;
}

export interface IAccModel {
  items: Array<IAcc>;
  register: Action<{}, IAcc['id']>;
  closeOthers: Action<{}, IAcc['id']>
}

export interface IAcc {
  id: string;
  triggerClose: number;
}

export interface IDwdModel {
  url: string;
  update: Action<{}, string>;
}

export interface ISelectedWarnArea {
  area: number | undefined;
  setArea: Action<ISelectedWarnArea, number>;
}

export interface IState {
  notify: INotifyModel;
  accordeon: IAccModel;
  dwdUrl: IDwdModel;
  tabVisible: boolean;
  updateTabVisible: Action<{}, boolean>;
  selectedWarnArea: ISelectedWarnArea;
}

const store = createStore<IState>({
  notify: {
    item: {
      shown: false,
      message: '',
      severity: 'error',
    },
    set: action((state: any, payload: INotify) => {
      state.item = payload;
    }),
  },
  dwdUrl: {
    url: '',
    update: action((state: any, payload: string) => {
      state.url = payload;
    }),
  },
  tabVisible: true,
  updateTabVisible: action((state: any, payload: boolean) => {
    state.tabVisible = payload;
  }),
  selectedWarnArea: {
    area: undefined,
    setArea: action((state, areaNumber: number | undefined) => {
      state.area = areaNumber;
    }),
  },
  // accordeons: {
  //   accs: [],
  //   addAcc: action((state, item: IAccordeon) => {
  //     state.accs = []
  //   })
  // },

  accordeon: {
    items: [],
    register: action((state: any, id: IAcc['id']) => {
      if (!id) return;
      state.items.push({
        id,
        triggerClose: 0,
      });
    }),
    closeOthers: action((state: any, id: IAcc['id']) => {
      state.items.forEach(el => {
        if (el.id !== id) {
          el.triggerClose += 1;
        }
      })
    })
  },
});

export default store;
