import '@material-ui/core';
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import '@material-ui/lab';
import '@mui/material';
import {
    DataGrid,
    deDE,
    GridColumns,
    GridSortModel,
    GridRenderCellParams
} from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import IIndex from "../../../models";
import IConfig, { IRiverArea } from "../../../models/config";
import { IMeasurements } from '../../../models/misc';
import { momentFullFormat } from "../../../service/chartDataHandler";
import globalService from "../../../service/globalService";
import "./VLevelList.scss";

interface IProps {
}

const VLevelList = (props: IProps) => {

    const [config, setConfig] = useState<IConfig>(null)
    const [index, setIndex] = useState<IIndex>(null)
    const defaultSortModel: GridSortModel = [{ field: 'name', sort: 'asc' }]

    const getConfig = async () => {
        const res = await globalService.getConfig()
        if (res) {
            setConfig(res)
        }
    }

    const getIndex = async () => {
        const res = await globalService.getIndex()
        if (res) setIndex(res)
    }

    function handleSortModelChange(newSortModel) {
        if (newSortModel.length === 0) {
            newSortModel.push(defaultSortModel[0])
        }
    }

    const columns: GridColumns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            minWidth: 145,
            sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
            renderCell: (params) => {
                const row = params.row
                return (row.riverAreaSlug && row.slug) ? (
                    <a href={('/flussgebiet/') + row.riverAreaSlug + '/' + row.slug}>{params.value}</a>
                ) : (
                    params.value
                )
            }
        },
        {
            field: 'riverName',
            headerName: 'Gewässer',
            flex: 1,
            minWidth: 150
        },
        {
            field: 'riverAreaName',
            headerName: 'Flussgebiet',
            flex: 1,
            minWidth: 100
        },
        {
            field: 'yLast',
            type: 'number',
            headerName: 'Letzte',
            flex: 1,
            minWidth: 100,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params: GridRenderCellParams<number>) => params.value && (params.value + ' ' +  params.row.unit),
        },
        {
            field: 'xLast',
            type: 'dateTime',
            headerName: 'Messung',
            flex: 1,
            minWidth: 160,
            renderCell: (params: GridRenderCellParams<string>) => params.value && (momentFullFormat(params.value) + ' Uhr'),
        },
        {
            field: 'legendColor',
            headerName: 'Status',
            flex: 1,
            minWidth: 70,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: GridRenderCellParams<string>) => (<div style={{background: params.value}} className="v-level-list__status"></div>)
        },
        {
            field: 'catchmentArea',
            type: 'number',
            headerName: 'Einzugsgebiet',
            flex: 1,
            minWidth: 135,
            renderCell: (params: GridRenderCellParams<number>) => params.value && (params.value + ' km\u00B2'),
        },
        {
            field: 'mh1',
            type: 'number',
            headerName: 'MH1',
            flex: 1,
            minWidth: 70,
            sortable: false,
            renderCell: (params: GridRenderCellParams<number>) => params.value && (params.value + ' ' +  params.row.unit),
        }
    ]

    const rows = config && index ? Object.entries(config.measurementsite).map(([msNumber, msConfig]) => {
        const msData: IMeasurements | null = index.measurementSites ? index.measurementSites[msNumber] : null
        const riverAreaConfig: IRiverArea = config.riverareas[msConfig.riverAreas[0]]
        const unit: string = msConfig.isSeaSite ? 'm ü. NHN' : 'cm'
        return {
            // Id field is mandatory for mui data grids.
            id: msNumber,
            name: msConfig.name,
            slug: msConfig.slug,
            riverAreaName: riverAreaConfig.name,
            riverAreaSlug: riverAreaConfig.slug,
            riverName: config.rivers[msConfig.rivers[0]]?.name,
            xLast: msData?.xLast,
            yLast: msData?.yLast,
            unit: unit,
            legendColor: msData?.legendColor,
            catchmentArea: msConfig.catchmentArea?.toLocaleString(),
            mh1: msConfig.mh1
        }
    }) : null

    useMemo(() => {
        getConfig()
        getIndex()
    }, [])

    return (
        <div className="v-level-list container">
            <Grid container spacing={4}>
                <Grid item xs={12} md={9}>
                    <h1>Liste der Pegel</h1>
                    {rows &&
                        <DataGrid
                            rowHeight={32}
                            headerHeight={32}
                            rows={rows}
                            columns={columns}
                            onSortModelChange={handleSortModelChange}
                            disableColumnMenu
                            autoHeight
                            disableSelectionOnClick
                            rowsPerPageOptions={[]}
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            initialState={{
                                sorting: {
                                    sortModel: defaultSortModel
                                }
                            }}
                        />
                    }
                </Grid>

                <Grid item xs={12} md={3}>
                    <div className="l-content-box">
                        <Button variant="contained" href={'/'} className="button">Zurück zur Kartenansicht</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default VLevelList;
