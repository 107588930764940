import React, { ReactNode } from "react";

class ErrorBoundary extends React.Component<{errorMsg?: string, children: ReactNode} , {hasError?: boolean, error?: any, errorInfo?: any}> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if (this.state.hasError) {
            return <h3>{this.props.errorMsg}</h3>;
        }

        return this.props.children

    }
}

export default ErrorBoundary;