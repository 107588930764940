import {useState} from 'react';
import "./MDetailboxWaterlevels.scss"
import {Box, Tab, Tabs} from "@material-ui/core";
import IIndex from "../../../models/index";
import IConfig, {IRiverArea} from "../../../models/config";
import AsyncHelper from './MDetailboxWaterlevels__AsyncHelper'


interface IProps {
    index?: IIndex;
    config: IConfig;
    activeRiverAreaId?: number
}

const MDetailboxWaterlevels = (props: IProps) => {

    const [tabValue, setTabValue] = useState(0)
    const changeActiveTab = (event, newValue) => {
        setTabValue(newValue)
    }

    const TabPanel = (props) => {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box className="m-detailbox-waterlevels__tab-box" p={3}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const activeRiverArea: IRiverArea | null = props.config.riverareas[props.activeRiverAreaId]

    return (
        <div className="m-detailbox-waterlevels">
            <Box className="m-detailbox-waterlevels__navigation">
                <Tabs value={tabValue}
                    onChange={changeActiveTab}
                    aria-label="m-detailbox-waterlevels__tabs"
                    variant="scrollable"
                    scrollButtons={activeRiverArea ? 'off' : 'on'}
                >
                    {activeRiverArea ?
                        (
                            <Tab label={activeRiverArea.name} className="m-detailbox-waterlevels__tab-button"/>
                        ) : (
                            Object.keys(props.config.riverareas).map((raId) => {
                                const riverArea = props.config.riverareas[raId]
                                return (
                                    <Tab
                                        label={riverArea.name}
                                        key={raId}
                                        className="m-detailbox-waterlevels__tab-button"
                                    />
                                )
                            })
                        )
                    }
                </Tabs>
            </Box>
            {activeRiverArea ?
                (
                    <TabPanel value={tabValue} index={0}>
                        <AsyncHelper
                            index={props.index}
                            config={props.config}
                            riverAreaId={props.activeRiverAreaId}
                        />
                    </TabPanel>
                ) : (
                    Object.keys(props.config.riverareas).map((raId, i) => {
                        return (
                            <TabPanel value={tabValue} index={i} key={raId}>
                                <AsyncHelper
                                    index={props.index}
                                    config={props.config}
                                    riverAreaId={parseInt(raId)}
                                />
                            </TabPanel>
                        )
                    })
                )
            }
        </div>
    );
}
;

export default MDetailboxWaterlevels;