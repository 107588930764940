import L, { Map } from 'leaflet'
import { GeoJSON, GeoJSONProps, useMap } from "react-leaflet"
import { checkTouchDevice, unproject } from "../../../utils/mapUtils"
import './EMap__Polygon.scss'

/**
 * Polygon Component Leaflet Layer
 * used to display and handle preAlertRegions
 */
interface IProps {
    data: GeoJSONProps['data']
    fillColor?: string
    pane: string
    onClick?: (e: any, map: Map) => void
}

const isTouchDevice = checkTouchDevice();

const EMap__PreAlertRegionPolygon = (props: IProps) => {
    const map = useMap()

    const opts = {
        data: props.data,
        coordsToLatLng: unproject,
        noClip: true,
        shadowPane: '',
        interactive: !!props.onClick,
        className: 'polygon' + (props.onClick ? ' polygon--has-data' : ''),
        renderer: L.svg({ pane: props.pane }),
        style: {
            fillColor: props.fillColor || '#fff',
            color: '#333',
            fillOpacity: 1,
            weight: .5,
        },
        eventHandlers:
            props.onClick ? {
                click(e) {
                    e.originalEvent._stopped = true;

                    props.onClick(e, map);
                },
                mouseover: (e) => {
                    if (isTouchDevice) {
                        return;
                    }

                    e.originalEvent._stopped = true;
                    e.layer.getElement().style.opacity = '0.7'
                },
                mouseout: (e) => {
                    if (isTouchDevice) {
                        return;
                    }

                    e.originalEvent._stopped = true;
                    e.layer.getElement().style.opacity = '1'
                },
            } : null,
    }

    return <GeoJSON {...opts}/>
}

export default EMap__PreAlertRegionPolygon