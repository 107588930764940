import IconAlert from '../../../assets/images/icon-alert.svg';
import IconSuccess from '../../../assets/images/icon-success.svg';
import IconInfo from '../../../assets/images/icon-info.svg';
import { IHint } from "../../../models";
import EHelpIcon from "../help-icon/EHelpIcon";
import './EMessage.scss';

interface IProps {
    hint: IHint;
    isDetailView: boolean
}

const EMessage = (props: IProps) => {

    const getIcon = (hint: IHint): string | undefined => {
        if (hint) {
            switch (hint.type) {
                case 'info':
                    return IconSuccess
                case 'error':
                    return IconAlert
                case 'warning':
                    return IconInfo
                default:
                    return undefined
            }
        } else return undefined

    }

    const Icon: string | undefined = getIcon(props.hint)

    return (props.hint &&
        <div className={"e-message e-message--severity-" + props.hint.type + (props.isDetailView ? " isDetail" : "")}
             data-testid="message">
            <div className="e-message__icon-wrap">
                {
                    Icon && (
                        <img
                            className={"e-message__icon e-message__icon__type-" + (props.hint.type)}
                            src={Icon}
                            alt={"icon-" + props.hint.type}
                            data-testid="message-image"
                        />
                    )
                }
            </div>
            <div className="e-message__content-wrap">
                <EHelpIcon hash={'hinweise'} label="Hinweis zur Hochwassersituation"/>
                {
                    props.hint.text && <div className="e-message__content-inner" dangerouslySetInnerHTML={{__html: props.hint.text}}></div>
                }
            </div>
        </div>
    );
};

export default EMessage;