import './LApp.scss';
import ESnackbar from "../elements/snackbar/ESnackbar";
import LBreadcrumbBar from './breadcrumb-bar/LBreadcrumbBar';
import LFooter from './footer/LFooter'
import LHeader from './header/LHeader'
import LRoutes from '../routes/LRoutes'
import {useLocation} from 'react-router-dom';


const LApp = () => {
    const {pathname} = useLocation()

    return (
        <div className="l-app">
            <ESnackbar/>
            <LHeader/>

            <span id="back-to-top-anchor"></span>
            <main className="l-main">
                {/* Don't show breadcrump on home page. */}
                {pathname !== '/' && <LBreadcrumbBar/>}

                <LRoutes/>
            </main>

            {/* Don't show footer on home page, it will be included in map content overlay. */}
            {pathname !== '/' && <LFooter/>}
        </div>
    )
}

export default LApp;
