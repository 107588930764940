import { ExpandMore } from "@material-ui/icons";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import routes from "../../../config/routes";
import IConfig from "../../../models/config";
import ErrorBoundary from "../../elements/error-boundary/EErrorBoundary";
import "./MMainMenu.scss";

interface IProps {
    isOpen: boolean;
    closeMenu: Function;
    config: IConfig | null
}

const MMainMenu = (props: IProps) => {

    const [expanded, setExpanded] = useState<string | false>(false)
    const tabIndex = props.isOpen ? 1 : -1

    const closeMenu = () => {
        props.closeMenu()
    }

    useEffect(() => {
        // Close accordions when menu closes.
        if (!props.isOpen) {
            setExpanded(false)
        }
    }, [props.isOpen])

    // Define menu items.
    const navItems = [
        routes.home,
        {label: 'Berichte', items: [
                routes.statusReport,
                routes.weatherReport,
        ]},
        routes.warnings,
        {label: 'Flussgebiete', items: props.config && Object.keys(props.config.riverareas).map((id) => (
            {label: props.config.riverareas[id].name, path: '/flussgebiet/' + props.config.riverareas[id].slug}
        ))},
        routes.levelList,

        {label: 'Karten', items: [
            {label: 'Hochwasserwarnkarte', path: routes.home.path},
            routes.rainfall
        ]},

        {label: 'Weitere Infos', items: [
            routes.news,
            routes.help,
            routes.faq,
            routes.sources,
            routes.levelReportingMarks,
            routes.publications,
            routes.links,
            routes.contact,
        ]}
    ]

    /**
     * Utility component to render optionally expandable navigation items.
     */
    const NavItem = (props) => (
        <div className="nav-item">
            <Link to={props.path} className="nav-link" onClick={closeMenu} tabIndex={tabIndex}>{props.label}</Link>
        </div>
    )

    return (
        <div className={"m-main-menu" + (props.isOpen ? " m-main-menu--open" : "")} data-testid="main-menu">
            <div className="container">
                <div className="m-main-menu__main-area">
                    <nav className="m-main-menu__nav-wrap">
                        <div className="m-main-menu__navigation">
                            {navItems.map((item: any, i) => (
                                // Handle expandable nav items.
                                item.items?.length ? (
                                    <Accordion expanded={expanded === item.label} className="nav-item" onChange={(_, isExpanded: boolean) => setExpanded(isExpanded ? item.label : false)} key={i}>
                                        <AccordionSummary expandIcon={<ExpandMore/>} tabIndex={tabIndex}>
                                            <span className="nav-link">{item.label}</span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {item.items.map((item, j) => (
                                                <NavItem {...item} key={j}/>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                )
                                // Handle single line nav items.
                                : (
                                    <NavItem {...item} key={i}/>
                                )
                            ))}
                        </div>
                    </nav>

                    <div className="m-main-menu__sidebar-wrap">
                        <div className="sidebar-area sidebar-link-area">
                            <h2>Direktlinks</h2>
                            <div className="sidebar-area__links-wrap">
                                <ErrorBoundary>
                                    {Object.keys(props.config.riverareas).map((id, i) => (
                                        <Link to={'/flussgebiet/' + props.config.riverareas[id].slug} className="sidebar-link" tabIndex={tabIndex} onClick={closeMenu} key={i}>{props.config.riverareas[id].name}</Link>
                                    ))}
                                </ErrorBoundary>
                            </div>
                        </div>
                        <div className="sidebar-area sidebar-link-area">
                            <h2 className="m-main-menu__tile__headline">Wetterwarnungen</h2>
                            <small>des Deutschen Wetterdienstes</small>
                            <div>
                                Karte Deutscher Wetterdienst - Wetterwarnungen Rheinland-Pfalz/Saarland
                            </div>
                            <a
                                className="sidebar-link-area__read-more"
                                href="http://www.dwd.de/DE/wetter/warnungen_gemeinden/warnkarten/warnWetter_rps_node.html?bundesland=rps"
                                target="_blank"
                                rel="noreferrer"
                            >Mehr erfahren</a>
                        </div>
                        <div className="sidebar-area sidebar-link-area">
                            <h2 className="m-main-menu__tile__headline">Hochwassermanagement</h2>
                            <small>Hochwassergefahren- und Hochwasserrisikokarten</small>
                            <div>
                                Hochwassergefahren- und Hochwasserrisikokarten sind die wirksamste Informationsgrundlage
                                über die hochwassergefährdeten Flächen und das Ausmaß der dort vorhandenen Risiken.
                            </div>
                            <a className="sidebar-link-area__read-more"
                               href="https://hochwassermanagement.rlp-umwelt.de/servlet/is/8662/"
                               target="_blank"
                               rel="noreferrer"
                            >Mehr erfahren</a>
                        </div>
                    </div>
                </div>
                <div className="m-main-menu__bottom-area">
                    <nav className="footer-nav">
                        <Link to={routes.contact.path} className="footer-nav__link" tabIndex={tabIndex} onClick={closeMenu}>{routes.contact.label}</Link>
                        <Link to={routes.imprint.path} className="footer-nav__link" tabIndex={tabIndex} onClick={closeMenu}>{routes.imprint.label}</Link>
                        <Link to={routes.privacyPolicy.path} className="footer-nav__link" tabIndex={tabIndex} onClick={closeMenu}>{routes.privacyPolicy.label}</Link>
                        <Link to={routes.accessibility.path} className="footer-nav__link" tabIndex={tabIndex} onClick={closeMenu}>{routes.accessibility.label}</Link>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default MMainMenu;
