import './LFooter.scss';
import {Link} from "react-router-dom";
import routes from "../../../config/routes";
import EBackToTop from "../../elements/back-to-top/EBackToTop"

const LFooter = () => (
    <footer className="l-footer">
        <EBackToTop/>
        <div className="l-footer__main">
            <div className="l-footer__container container">
                <p className="l-footer__subheading">ÜBER HOCHWASSERVORHERSAGEZENTRALE RHEINLAND-PFALZ</p>
                <nav className="l-footer__nav">
                    <Link to={routes.contact.path} className="l-footer__link">Kontakt</Link>
                    <Link to={routes.imprint.path} className="l-footer__link">Impressum</Link>
                    <Link to={routes.privacyPolicy.path} className="l-footer__link">Datenschutz</Link>
                    <Link to={routes.accessibility.path} className="l-footer__link">Barrierefreiheit</Link>
                </nav>
            </div>
        </div>
    </footer>
);

export default LFooter;