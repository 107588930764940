import { useEffect, useState } from "react";
import globalService from "../../../service/globalService";
import "./MStaticContent.scss";
import useTabActive from "../../../hooks/useTabActive";
import { useStoreState } from "easy-peasy";
import {
  destroyRefreshService,
  fetchCachedDwdImage,
  initRefreshService,
} from "../../../service/refreshService";
import { IState } from "../../../store/store";

const IMG_ID = 'wetterwarnungen'

const MStaticContent = () => {
  const [innerHTML, setInnerHTML] = useState(undefined);
  const [imgSrc, setImgSrc] = useState(undefined);

  /**
   * load the innerHTML of the "kacheln" file and set it the innerHTML state
   * init the refreshService with the source of the image to be refreshed
   * @returns {Promise<void>}
   */
  const loadInnerHTML = async () => {
    const res = await globalService.getPartial("/kacheln");
    if (res) {
      setInnerHTML(res);
      // parse the image src and init its refreshservice
      const parsed = new DOMParser().parseFromString(res, 'text/html');
      const parsedImgSrc = parsed.getElementById(IMG_ID)?.getAttribute('src');

      if (!parsedImgSrc) {
        console.error(`failed to parse IMG Source with id ${IMG_ID}`);
        return;
      }

      setImgSrc(parsedImgSrc);
      initRefreshService(parsedImgSrc);
    }
  };

  useEffect(() => {
    loadInnerHTML();
    return () => destroyRefreshService();
  }, []);

  const isTabActive = useTabActive();

  const dwdUrl = useStoreState<IState>((state) => state.dwdUrl.url);

  useEffect(() => {
    const img = document.getElementById(IMG_ID);
    if (img) {
      img.setAttribute("src", dwdUrl);
    }
  }, [dwdUrl]);

  //update image if user tabs in
  useEffect(() => {
    if (isTabActive && imgSrc) {
      fetchCachedDwdImage(imgSrc);
    }
  }, [isTabActive, imgSrc]);

  return (
    <div className="m-static-content">
      <div
        className="m-static-content__inner"
        dangerouslySetInnerHTML={{ __html: innerHTML }}
      />
    </div>
  );
};

export default MStaticContent;
