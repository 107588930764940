import apiClient, {validateResponse} from "./httpService";
import errorHandler from "./errorHandler";
import apiPaths from "../config/apiPaths";
import moment from "moment-timezone";
import ExpireCache from "../utils/ExpireCache";
import axios from "axios";

const partialsPath = '/static/shared/partials'

const requestCache: ExpireCache<any> = new ExpireCache<any>()
const pendingRequests: Map<string, any> = new Map<string, any>()

function createCachedRequest(path: string, expire: moment.Duration): Function {
    return async () => {
        // Check cache.
        const value = requestCache.get(path)
        if (value) {
            return value
        }

        // Check pending requests.
        if (pendingRequests[path]) {
            return pendingRequests[path];
        }

        try {
            // Load data from backend.
            const promise = pendingRequests[path] = apiClient.get(path).then((res) => {
                return validateResponse(res)
            })
            const value = await promise
            // Update cache.
            requestCache.set(path, value, moment().add(expire).valueOf())
            delete pendingRequests[path]
            return value
        } catch (err) {
            console.error(err)
            return errorHandler(err)
        }
    }
}

const globalService = {

    /**
     * Get Index
     */
    getIndex: createCachedRequest(apiPaths.index, moment.duration(5, 'minutes')),

    /**
     * Get Config
     */
    getConfig: createCachedRequest(apiPaths.config, moment.duration(10, 'minutes')),

    /**
     * Get MeasurementSite Details
     */
    getMeasurementSiteDetails: async (id: number) => {
        try {
            const path = apiPaths.measurmentSite
            const res = await apiClient.get(path + '/' + id)
            return validateResponse(res)
        } catch (err) {
            console.error(err)
            return errorHandler(err)
        }
    },

    /**
     * Get RiverArea Details
     */
    getRiverAreaDetails: async (id: number) => {
        try {
            const path = apiPaths.riverArea
            const res = await apiClient.get(path + '/' + id)
            return validateResponse(res)
        } catch (err) {
            console.error(err)
            return errorHandler(err)
        }
    },

    getRiverAreaSortedDetails: async (id: number) => {
        try {
            const path = apiPaths.riverArea
            const res = await apiClient.get(path + '/' + id)
            return validateResponse(res)
        } catch (err) {
            console.error(err)
            return errorHandler(err)
        }
    },

    /**
     * requesting status-reports
     */
    getStatusReports: async () => {
        try {
            const path = apiPaths.statusReport
            const res = await apiClient.get(path)
            return validateResponse(res)
        } catch (err) {
            console.error(err)
            return errorHandler(err)
        }
    },

    getPartial: async (fileName: string, path?: string): Promise<string> => {
        path = path || partialsPath
        const res = await axios.get(path + fileName + '.phtml')
        return validateResponse(res)
    }
}

export default globalService