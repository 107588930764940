/**
 * promise to resolve after given ms
 * @param ms
 */
export const delay = (ms) =>
  new Promise((res) => {
    setTimeout(() => {
      // @ts-ignore
      res();
    }, ms);
  });

/**
 * Get slug for given string. Converts german umlauts to oe, ue, äe, ...
 *
 * @param s: string | null
 * @return string | null
 */
export const slug = (s: string | null): string | null => {
    if (!s || !s.length) {
        return null;
    }
    const umlautMap = {
        '\u00fc': 'ue',
        '\u00e4': 'ae',
        '\u00f6': 'oe',
        '\u00df': 'ss'
    }
    return s.toLowerCase()
        .replace(new RegExp('[' + Object.keys(umlautMap).join('|') + ']', 'g'), (a) => umlautMap[a])
        .replaceAll(/[^A-Za-z0-9]+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}


/**
 * Pinched from underscore.js
 * Throttle a function call to a maximum of once per wait milliseconds.
 * @param func
 * @param wait
 * @param options
 * @returns {Function}
 * @private
 */
export const throttle = (func, wait, options = { leading: true, trailing: false }) => {
    let context, args, result;
    let timeout = null;
    let previous = 0;
    let later = function () {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
    };
    return function (event) {
        if(!event.originalEvent.isTrusted) {
            func.apply(this, arguments);
            return;
        }
        let now = Date.now();
        if (!previous && options.leading === false) previous = now;
        let remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
}
