import './EMap__Polygon.scss'
import { GeoJSON, GeoJSONProps } from "react-leaflet"
import { unproject } from "../../../utils/mapUtils"

/**
 * Polygon Component Leaflet Layer
 * used to display and handle lakes
 */
interface IProps {
    data: GeoJSONProps['data']
}

const EMap__LakePolygon = (props: IProps) => {
    const opts = {
        data: props.data,
        coordsToLatLng: unproject,
        // weight: .5,
        noClip: true,
        shadowPane: '',
        interactive: false,
        className: 'polygon',
        style: {
            fillColor: '#5c7ea2',
            color: 'blue',
            fillOpacity: 1,
            weight: 0
        }
    }

    return <GeoJSON {...opts}/>
}

export default EMap__LakePolygon