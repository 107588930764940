import "./VHome.scss"
import EMap from "../../elements/map/EMap"
import MStaticContent from "../../modules/static-content/MStaticContent"
import LFooter from "../../layout/footer/LFooter"

interface IProps {
}

const VHome = (props: IProps) => (
    <div className="v-home">
        <EMap>
            <section className="v-home__content">
                <div className="v-home__body">
                    <div className="container">
                        <MStaticContent/>
                    </div>
                </div>

                <LFooter/>
            </section>
        </EMap>
    </div>
)

export default VHome
