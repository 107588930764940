import CssBaseline from '@material-ui/core/CssBaseline';
import { StoreProvider } from 'easy-peasy';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './assets/styles/global.scss';
import LApp from './components/layout/LApp';
import reportWebVitals from './reportWebVitals';
import store from './store/store';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <>
        <CssBaseline/>
        <StoreProvider store={store}>
            <BrowserRouter>
                <LApp/>
            </BrowserRouter>
        </StoreProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
