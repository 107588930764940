import { useEffect, useState } from "react";
import store from "../store/store";

const useTabActive = () => {
  const [visibilityState, setVisibilityState] = useState(true);
  const handleVisibilityChange = function () {
    if (document.visibilityState === "visible") {
      setVisibilityState(true);
      store.getActions().updateTabVisible(true);
    } else {
      setVisibilityState(false);
      store.getActions().updateTabVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // cleanup this component
    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  return visibilityState;
};

export default useTabActive;
