import L from "leaflet";
import {
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  MapContainer,
  MapContainerProps,
  Pane,
  useMapEvents,
} from "react-leaflet";
import IIndex from "../../../models";
import IConfig from "../../../models/config";
import indexRequestHandler from "../../../service/globalService";
import {
  areaHashType,
  areaType,
  getShapeFileData,
} from "../../../service/shapefileHandler";
import Hatch from "../../../utils/Hatch";
import {
  calcTooltipDirection,
  checkTouchDevice,
  getIsSmallScreenSize,
} from "../../../utils/mapUtils";
import MContentBox from "../../modules/content-box/MContentBox";
import { MemoizedMDetailbox } from "../../modules/detail-box/MDetailbox";
import MInfobox from "../../modules/info-box/MInfobox";
import "./EMap.scss";
import AlertRegionPolygon from "./EMap__AlertRegionPolygon";
import PreAlertRegionPolygon from "./EMap__PreAlertRegionPolygon";
import LakePolygon from "./EMap__LakePolygon";
import { EMap__MemoizedMeasurementSiteMarkers as MemoizedMeasurementSiteMarkers } from "./EMap__MeasurementSiteMarkers";
import {
  EMap__IMeasurementSitePreview as IMeasurementSitePreview,
  EMap__MeasurementSitePreview as MeasurementSitePreview,
} from "./EMap__MeasurementSitePreview";
import NeighbourCountryPolygon from "./EMap__NeighbourCountryPolygon";
import RiverPolygon from "./EMap__RiverPolygon";
import useEventForwarder from "../../../utils/useEventForwarder";
import store from "../../../store/store";

interface IProps {
  children: ReactNode;
}

const EMap = (props: IProps) => {
  const [index, setIndex] = useState<IIndex | null>(null);
  const [config, setConfig] = useState<IConfig | null>(null);

  const [alertRegions, setAlertRegions] = useState([]);
  const [preAlertRegions, setPreAlertRegions] = useState([]);

  const [riverLines, setRiverLines] = useState([]);
  const [stations, setStations] = useState([]);
  const [lakes, setLakes] = useState([]);
  const [neighbourcountries, setNeighbourcountries] = useState([]);
  // const [riverAreas, setRiverAreas] = useState([])
  const [loading, setLoading] = useState(true);

  const [showAlertRegions, setShowAlertRegions] = useState(true);
  const [showRivers, setShowRivers] = useState(true);
  const [showStations, setShowStations] = useState(true);

  const [infoboxOpen, setInfoboxOpen] = useState(!getIsSmallScreenSize());
  const [detailboxOpen, setDetailboxOpen] = useState(false);
  const [contentBoxOpen, setContentBoxOpen] = useState(false);

  const map = useRef<L.Map>(null);

  const [mobilePreview, setMobilePreview] =
    useState<IMeasurementSitePreview | null>(null);

  const { initEventForwarder } = useEventForwarder(100);

  const isTouchDevice = checkTouchDevice();

  const getIndex = async () => {
    const res = await indexRequestHandler.getIndex();
    if (res) {
      setIndex(res);
    }
    return res;
  };

  const getConfig = async () => {
    const res = await indexRequestHandler.getConfig();
    if (res) {
      setConfig(res);
    }
    return res;
  };

  /**
   * Get shape files from session storage or fetch from remote if hash has changed.
   */

  const getAlertRegions = async (fileHash: string, index: IIndex) => {
    await getShapeFileData(
      "alertRegions",
      (res) => {
        // Group alert regions by `TYPE`.
        const alertRegions = [];
        const preAlertRegions = [];

        res.forEach((data) => {
          const properties = data.properties;
          const type = properties.TYPE || 0;

          if(index.alertregions[properties.ID]?.preAlert) {
            const group = preAlertRegions[type] || [];
            group.push(data);
            preAlertRegions[type] = group;
            return;
          }

          const group = alertRegions[type] || [];
          group.push(data);
          alertRegions[type] = group;
        });

        // Sort groups by `DRAW_ORDER`.
        alertRegions.forEach((group: Array<any>) => {
          group.sort((a, b) => {
            a = a.properties.DRAW_ORDER || 0;
            b = b.properties.DRAW_ORDER || 0;
            return a - b;
          });
        });

        preAlertRegions?.forEach((group: Array<any>) => {
          group.sort((a, b) => {
            a = a.properties.DRAW_ORDER || 0;
            b = b.properties.DRAW_ORDER || 0;
            return a - b;
          });
        });
        
        alertRegions[0] = alertRegions[0] || [];
        alertRegions[1] = alertRegions[1] || [];
        
        setAlertRegions(alertRegions);
        setPreAlertRegions(preAlertRegions);
      },
      "alertRegionsHash",
      fileHash
    );
  };

  const getRiverLines = async (fileHash: string) => {
    await getShapeFileData(
      areaType.riverLines as any,
      setRiverLines,
      areaHashType.riverLines as any,
      fileHash
    );
  };

  // const getRiverAreas = async (fileHash: string) => {
  //     await getShapeFileData(areaType.riverAreas, setRiverAreas, areaHashType.riverAreas, fileHash)
  // }

  const getStations = async (fileHash: string) => {
    await getShapeFileData(
      areaType.stations as any,
      setStations,
      areaHashType.stations as any,
      fileHash
    );
  };

  const getLakes = async (fileHash: string) => {
    await getShapeFileData(
      areaType.lakes as any,
      setLakes,
      areaHashType.lake as any,
      fileHash
    );
  };

  const getNeighbourcountries = async (fileHash: string) => {
    await getShapeFileData(
      areaType.neighbourcountry as any,
      setNeighbourcountries,
      areaHashType.neighbourcountry as any,
      fileHash
    );
  };

  const getAlertRegionColor = (id: number) => {
    const initAlertRegion = index.alertregions[id];
    if (initAlertRegion) {
      return config.alertclasses[initAlertRegion.alertClassId].color;
    } else return "#93C66A";
  }

  const getPreAlertRegionColor = (id: number) => {
    const initAlertRegion = index.alertregions[id];
    if(initAlertRegion) {
      if(initAlertRegion.preAlert) {
        return `url(#Hatch_${initAlertRegion.alertClassId})`;
      }
      return config.alertclasses[initAlertRegion.alertClassId].color;
    }
    return "#93C66A";
  }

  /**
   * get all necessary data when component initially renders
   */
  useMemo(() => {
    Promise.all([getConfig(), getIndex()]).then(([config, index]) => {
      const { files } = config;
      Promise.all([
        getAlertRegions(files.warnregionen, index),
        getStations(files.pegelorte),
      ]).then(() => {
        // Hide loader as soon as config, index, alert regions and measurement sites are loaded.
        setLoading(false);
      });
      getRiverLines(files.gewaesser);
      // getRiverAreas(files.flussgebiete)
      getLakes(files.flaechengewaesser);
      getNeighbourcountries(files.nachbarland);
    });
  }, []);

  /**
   * Determine viewport center based on current and given new overlay boxes open states.
   */
  const getViewportCenter = useCallback(
    (newState?, center?: L.LatLng) => {
      if (!newState) {
        newState = { infoboxOpen, detailboxOpen, contentBoxOpen };
      }

      const customCenter: boolean = !!center;
      if (!center) {
        // Use current map center or center of bounding box of Rhineland Palatine, if map is not initialized yet.
        // Hint: The "real" center of RLP is 49.913056, 7.45 but we need a little offset to fit map overlay boxes.
        center = map.current?.getCenter() || L.latLng(49.913056, 6.9);
      }

      // Short path for small screens where center does not change when toggling overlay boxes.
      if (getIsSmallScreenSize()) {
        return center;
      }

      // Calculate current content width.
      const vpWidth = window.innerWidth;
      const vpHeight =
        document.getElementsByClassName("e-map")[0]?.getBoundingClientRect()
          .height || 0;
      const contentWidth = Math.min(vpWidth, 1200) - 28; // Minus padding
      // Restrict viewport width to 2x content width.
      const maxVpWidth = Math.min(vpWidth, contentWidth * 2);
      const deltaVpWidth = vpWidth - maxVpWidth;
      // Calculate relevant measures of info and detail boxes instead of getting size from dom because at current time they may be collapsed and its size will change during upcoming expansion.
      const infoBoxRight = (vpWidth - contentWidth) / 2 + contentWidth * 0.33;
      const detailBoxLeft =
        (vpWidth - contentWidth) / 2 + contentWidth * (1 - 0.6);

      const zoom = getIsSmallScreenSize() ? 8 : 9;

      let p: L.Point;
      // Check if map has already been initialized.
      if (map.current) {
        p = map.current.latLngToContainerPoint(center);

        // Reset center to collapsed boxes.
        if (infoboxOpen) {
          p = p.add([(infoBoxRight - deltaVpWidth) / 2, 0]);
        }
        if (detailboxOpen) {
          p = p.add([-(detailBoxLeft - deltaVpWidth) / 2, 0]);
        }
      } else {
        // Because map is not initialized yet, we have to do coordinate to pixel conversion ourselves.
        let pCenter = L.CRS.EPSG3857.latLngToPoint(center, zoom).round();
        let viewHalf = L.point(vpWidth / 2, vpHeight / 2);
        let pixelOrigin = pCenter.subtract(viewHalf);
        p = pCenter.subtract(pixelOrigin);
      }

      // Initially offset center to not overlap content box preview.
      if (!map.current || customCenter) {
        p = p.add(L.point(0, (100 / 2) * (customCenter ? -1 : 1)));
      }

      // Transition center to new collapsing state of boxes.
      if (newState.infoboxOpen) {
        p = p.add([-(infoBoxRight - deltaVpWidth) / 2, 0]);
      }
      if (newState.detailboxOpen) {
        p = p.add([(detailBoxLeft - deltaVpWidth) / 2, 0]);
      }

      if (map.current) {
        center = map.current.containerPointToLatLng(p);
      } else {
        // Because map is not initialized yet, we have to do pixel to coordinate conversion ourselves.
        let pCenter = L.CRS.EPSG3857.latLngToPoint(center, zoom).round();
        let viewHalf = L.point(vpWidth / 2, vpHeight / 2);
        let pixelOrigin = pCenter.subtract(viewHalf);
        p = p.add(pixelOrigin);
        center = L.CRS.EPSG3857.pointToLatLng(p, zoom);
      }

      return center;
    },
    [infoboxOpen, detailboxOpen, contentBoxOpen, map]
  );

  /**
   * Fit viewport to given bounds respecting overlay box state.
   */
  const fitBounds = useCallback(
    (bounds: L.LatLngBounds) => {
      if (!map.current) {
        return;
      }

      const m = map.current as any;
      const target = m._getBoundsCenterZoom(bounds, {
        padding: getIsSmallScreenSize() ? [0, 0] : [150, 150],
      });

      const center: L.LatLng = m.getCenter();
      const zoom = m.getZoom();

      // Set map zoom to fitted bounds.
      m.setView(center, target.zoom, true);
      const newCenter = getViewportCenter(
        { infoboxOpen: false, detailboxOpen: false, contentBoxOpen: false },
        center
      );
      const [deltaLat, deltaLng] = [
        center.lat - newCenter.lat,
        center.lng - newCenter.lng,
      ];

      target.center.lat += deltaLat;
      target.center.lng += deltaLng;

      // Reset map zoom to start.
      m.setView(center, zoom, true);

      m.setView(target.center, target.zoom);
    },
    [getViewportCenter]
  );

  const toggleInfoBox = useCallback(
    (isOpen) => {
      setInfoboxOpen(isOpen);
      if (isOpen) {
        setDetailboxOpen(false);
        setContentBoxOpen(false);
      }
      // Offset map so that box does not overlap current map center.
      if (map.current) {
        setTimeout(() => {
          map.current.setView(
            getViewportCenter({
              infoboxOpen: isOpen,
              detailboxOpen: false,
              contentBoxOpen: false,
            })
          );
        }, 200);
      }
    },
    [getViewportCenter]
  );

  const toggleDetailBox = useCallback(
    (isOpen) => {
      setDetailboxOpen(isOpen);
      if (isOpen) {
        setInfoboxOpen(false);
        setContentBoxOpen(false);
      }
      // Offset map so that box does not overlap current map center.
      if (map.current) {
        setTimeout(() => {
          map.current.setView(
            getViewportCenter({
              infoboxOpen: false,
              detailboxOpen: isOpen,
              contentBoxOpen: false,
            })
          );
        }, 200);
      }
    },
    [getViewportCenter]
  );

  const toggleContentBox = useCallback(
    (isOpen) => {
      setContentBoxOpen(isOpen);
      setInfoboxOpen(!getIsSmallScreenSize() && !isOpen);
      if (isOpen) {
        setDetailboxOpen(false);
      }
      // Offset map so that box does not overlap current map center.
      if (map.current) {
        setTimeout(() => {
          map.current.setView(
            getViewportCenter({
              infoboxOpen: !getIsSmallScreenSize() && !isOpen,
              detailboxOpen: false,
              contentBoxOpen: isOpen,
            })
          );
        }, 200);
      }
    },
    [getViewportCenter]
  );

  /**
   * Component to register events on leaflet map.
   */
  const MapEvents = () => {
    const map = useMapEvents({
      layeradd: (e) => {
        // Set render element clipping tolerance to work around clipped edges issue while panning.
        const pane = (e.layer as any).options.pane;
        const renderer = map.getRenderer(e.layer as L.Path);
        // How much to extend the clipped map area around the viewport in % viewport size. Defaults to .1 = 10%.
        renderer.options.padding =
          pane === "regionsdata" /* || pane === 'rivers'*/ ? 1 : 0.33; // Set 100% for alert regions all others 33%
        // How much click tolerance for touch events in pixel.
        renderer.options.tolerance = isTouchDevice ? 8 : 2;
      },

      // resize: () => {
      //     map.setView(getIsSmallScreenSize() ? [43.9, 3.45] : [44.4, 3.5], map.getZoom())
      // },
      tooltipopen(e) {
        // Set tooltip direction.
        if (!isTouchDevice) {
          const direction = calcTooltipDirection(
            map.latLngToContainerPoint(e.tooltip.getLatLng()),
            map.getContainer().getBoundingClientRect()
          );
          if (e.tooltip.options.direction !== direction) {
            e.tooltip.options.direction = direction;
            e.tooltip.update();
          }
        }
      },
    });

    return null;
  };

  // Initial map settings.
  const mapConfig = useMemo<MapContainerProps>(
    () => ({
      // Restricting bounds badly influences dynamic map repositioning when toggling overlay boxes. Additionally having this setting disabled seems to speed up map rendering performance.
      //maxBounds: L.latLngBounds(L.latLng(40.4, -2), L.latLng(47.4, 9)),
      center: getViewportCenter(),
      zoom: getIsSmallScreenSize() ? 7.5 : 8.5,
      zoomSnap: 0.25,
      minZoom: 7.5,
      maxZoom: 11,
      touchZoom: true,
      doubleClickZoom: false,
      // scrollWheelZoom: false

      // For large datasets this drastically speeds up map performance.
      preferCanvas: true,
      // This is not working as expected and we have to set these option in addlayer event.
      // renderer: L.canvas({
      //     padding: .66,
      //     // tolerance: 10
      // }),

      // Store reference to leaflet instance.
      whenCreated: (m) => {
        map.current = m;
        // fix lag on first zoom
        map.current.zoomIn(0.01, { animate: false })
        setTimeout(() => {
            map.current.zoomOut(0.01, { animate: false })
        }, 0)
        initEventForwarder(m);
      },
    }),
    [getViewportCenter]
  );

  const AlertRegionPane = ({ index, showAlertRegions }: { index: number; showAlertRegions: boolean }) => {
    const regions = alertRegions[index];
    if (!regions || regions.length === 0) return null;
    
    return (
      <Pane name={`regionsdata_${index}`} style={{ zIndex: 400 + index }} key={`alert_${index}`}>
        {regions.map((data: any, j: number) => (
          <AlertRegionPolygon
            data={data}
            key={`${j}_${showAlertRegions}`}
            fillColor={showAlertRegions ? getAlertRegionColor(data.properties.ID) : undefined}
            onClick={showAlertRegions ? (e) => {
              store.dispatch.selectedWarnArea.setArea(data.properties.ID);
              toggleDetailBox(true);
            } : undefined}
          />
        ))}
      </Pane>
    );
  };
  
  const PreAlertRegionPane = ({ index, showAlertRegions }: { index: number; showAlertRegions: boolean }) => {
    const regions = preAlertRegions[index];
    if (!regions || regions.length === 0) return null;
    const paneName = `regionsdata_2_${index}`;
    return (
      <Pane name={paneName} style={{ zIndex: 401 + index }} key={`preAlert_${index}`}>
        {regions.map((data: any, j: number) => (
          <PreAlertRegionPolygon
            data={data}
            key={`${j}_${showAlertRegions}`}
            pane={paneName}
            fillColor={showAlertRegions ? getPreAlertRegionColor(data.properties.ID) : undefined}
            onClick={showAlertRegions ? (e) => {
              store.dispatch.selectedWarnArea.setArea(data.properties.ID);
              toggleDetailBox(true);
            } : undefined}
          />
        ))}
      </Pane>
    );
  };

  return (
    <div
      className={"e-map" + (loading ? " e-map--loading" : "")}
      data-testid="map"
    >
      {!loading && (
        <>
          <div className="e-map__overlay container">
            <MInfobox
              config={config}
              showAlertRegions={showAlertRegions}
              showRivers={showRivers}
              showStations={showStations}
              infoboxOpen={infoboxOpen}
              setShowAlertRegions={setShowAlertRegions}
              setShowRivers={setShowRivers}
              setShowStations={setShowStations}
              setInfoboxOpen={toggleInfoBox}
            />

            <MemoizedMDetailbox
              config={config}
              index={index}
              detailboxOpen={detailboxOpen}
              setDetailboxOpen={toggleDetailBox}
            />
          </div>

          <MContentBox
            isOpen={contentBoxOpen}
            setIsOpen={toggleContentBox}
            children={props.children}
          />

          <div className="e-map__hatches-prerender">
            {config.alertclasses &&
              Object.keys(config.alertclasses).map((id) => {
                const alertClass = config.alertclasses[id];
                return (
                  <Hatch
                    color={alertClass.color}
                    stripeColor={alertClass.stripeColor}
                    alertClassId={id}
                    key={id}
                  />
                );
              })}
          </div>

          <MapContainer
            {...mapConfig}
            className="e-map__map"
            data-testid="map-container"
          >
            <MapEvents />

            {/* DEBUG Show open street map layer to control coordinate projection <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            maxZoom={19}
                            attribution="© OpenStreetMap"
                        ></TileLayer> */}

            <Pane name="neighbourcountries" style={{ zIndex: 399 }}>
              {neighbourcountries.map((el: any, i: number) => {
                if (el.properties.NAME !== "Rheinland-Pfalz") {
                  return (
                    <NeighbourCountryPolygon
                      data={el}
                      name={el.properties.NAME}
                      url={el.properties.URL}
                      key={i}
                    />
                  );
                }
                return null;
              })}
            </Pane>

            {
              alertRegions.map((_, i) => (
                <Fragment key={`fragment_${i}`}>
                  <AlertRegionPane index={i} showAlertRegions={showAlertRegions} />
                  <PreAlertRegionPane index={i} showAlertRegions={showAlertRegions} />
                </Fragment>
              ))
            }

            <Pane name="lakes" style={{ zIndex: 405, pointerEvents: "none" }}>
              {showRivers &&
                lakes &&
                lakes.map((data: any, i: number) => (
                  <LakePolygon data={data} key={i} />
                ))}
            </Pane>

            <Pane name="rivers" style={{ zIndex: 406, pointerEvents: "none" }}>
              {showRivers &&
                riverLines.map((data: any, i: number) => (
                  <RiverPolygon data={data} key={i} />
                ))}
            </Pane>

            <Pane name="stations" style={{ zIndex: 407 }}>
              {showStations && (
                <MemoizedMeasurementSiteMarkers
                  stations={stations}
                  config={config}
                  index={index}
                  setMobilePreview={setMobilePreview}
                />
              )}
            </Pane>
          </MapContainer>

          {mobilePreview && (
            <MeasurementSitePreview
              {...mobilePreview}
              setMobilePreview={setMobilePreview}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EMap;
