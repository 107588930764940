import './LHeader.scss';
import {useEffect, useState} from "react"
import {Link} from "react-router-dom";
import routes from "../../../config/routes"
import LogoWhite from "../../../assets/images/logo-white.svg"
import LogoBlack from "../../../assets/images/logo-black.svg"
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import EHamburger from "../../elements/hamburger/EHamburger"
import MMainMenu from "../../modules/main-menu/MMainMenu"
import MMenuOverlay from "../../elements/menu-overlay/MMenuOverlay"
import ErrorBoundary from "../../elements/error-boundary/EErrorBoundary";
import IConfig from "../../../models/config";
import globalService from '../../../service/globalService';

interface IProps {
}

const LHeader = (props: IProps) => {

    /**
     * State to determine if the menu is open or closed
     */
    const [menuOpen, setMenuOpen] = useState<boolean>(false)
    const [config, setConfig] = useState<IConfig>(null)

    /**
     * Config-request to configure directlinks in main-menu
     */
     const getConfig = async () => {
        const config = await globalService.getConfig()
        if (config) {
            setConfig(config)
        }
    }

    useEffect(() => {
        getConfig()
    }, [])

    /**
     * Toggle the menuOpen value. Function usually fired from EHamburger component.
     * @param {boolean} [value = !menuOpen] Explicit value to set for menuOpen.
     */
    const toggleMenu = (value?: boolean): void => {
        if (value === undefined) {
            value = !menuOpen
        }
        // Windows scroll bar handling to prevent browser showing 2 scroll bars next to each other.
        document.body.style.overflow = value ? 'hidden' : 'auto'

        setMenuOpen(value)
    }

    return (
        <>
            <ClickAwayListener onClickAway={() => toggleMenu(false)}>
                <header className={"l-header" + (menuOpen ? " l-header--menu-open" : "")} data-testid="header">
                    <div className="l-header__header-wrap">
                        <div className="l-header__container container">
                            <div className="l-header__hamburger-wrap">
                                <EHamburger isOpen={menuOpen} toggleMenu={toggleMenu}/>
                            </div>
                            <h1 className="l-header__heading">
                            Hochwasservorhersagezentrale <span>Rheinland-Pfalz</span>
                            </h1>
                            <Link tabIndex={1} to={routes.home.path} className="l-header__logo-wrap" onClick={() => toggleMenu(false)}>
                                <img src={menuOpen ? LogoWhite : LogoBlack} alt="Rheinland Pfalz | Landesamt für Umwelt"/>
                            </Link>
                        </div>
                    </div>

                    <ErrorBoundary errorMsg={"Hauptmenü konnte nicht geladen werden"}>
                        {config &&
                            <MMainMenu
                                isOpen={menuOpen}
                                config={config}
                                closeMenu={() => toggleMenu(false)}
                            />
                        }
                    </ErrorBoundary>
                </header>
            </ClickAwayListener>
            <MMenuOverlay isOpen={menuOpen}/>
        </>
    );
}

export default LHeader;
