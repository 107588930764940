import { Chart, ChartData, Plugin, ScatterDataPoint } from "chart.js";
import "chartjs-adapter-moment";
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment-timezone';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { ILegend, IMeasurementSiteConfig } from "../../../models/config";
import { IMeasurements } from "../../../models/misc";
import { formatNumber, momentFormatHour, momentFullFormat, momentParse, validateArray } from "../../../service/chartDataHandler";
import chartColors from "../../../config/chart";
import { useResize } from "../../../utils/hooks";
import EErrorBoundary from "../error-boundary/EErrorBoundary";
import EHelpIcon from "../help-icon/EHelpIcon";
import ELegend, { IToggleableLegend } from '../legend/ELegend';
import "./EDetailChart.scss";
import { AnyObject } from "chart.js/types/basic";

interface IProps {
    msData: IMeasurements | null;
    msConfig: IMeasurementSiteConfig | null
    waterlevel?: boolean;
    thresholds: Record<string, number | null>;
    legends: Record<string, ILegend>;
    children?: React.ReactElement;
    label: string;
}

interface IRenderArgs {
    annotations: Object,
    legends: Record<string, IToggleableLegend>,
    markLegends: Record<string, IToggleableLegend>,
    options: Object,
    plugins: Plugin<'line', AnyObject>[],
    data: ChartData<"line">,
    p10, p20, p30, p40, p50, p60, p70, p80, p90, levelData
}

const EDetailChart = (props: IProps) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [renderArgs, setRenderArgs] = useState<IRenderArgs>(null)
    const chartRef = useRef(null);
    const windowSize = useResize();

    const mobileBreakPoint = 568
    const chartAspectRatio = (windowSize.width < mobileBreakPoint) ? 1 : 2
    const [displayLeftYAxis, setDisplayLeftYAxis] = useState(windowSize.width >= mobileBreakPoint);

    const updateRenderArgs = useCallback(() => {
        Chart.register(annotationPlugin)

        // Validate predictions/waterlevel data.
        const msData = props.msData
        const p10 = validateArray(msData.predictions?.p10)
        const p20 = validateArray(msData.predictions?.p20)
        const p30 = validateArray(msData.predictions?.p30)
        const p40 = validateArray(msData.predictions?.p40)
        const p50 = validateArray(msData.predictions?.p50)
        const p60 = validateArray(msData.predictions?.p60)
        const p70 = validateArray(msData.predictions?.p70)
        const p80 = validateArray(msData.predictions?.p80)
        const p90 = validateArray(msData.predictions?.p90)
        const levelData = validateArray(msData.measurements)

        // Define how much space should be drawn between end of data and chart border.
        const xRightPadding = moment.duration(6, 'hours')
        // Define xMin and yMax.
        const xMin = msData.xMin
        const xMax = msData.xMax ? +moment(msData.xMax).add(xRightPadding) : null

        const yMin = msData.yMin
        const yMax = msData.yMax

        // Define y step size for chart ticks.
        let yStepSize = props.waterlevel && msData.yStepSize
        let yStepSizeMajor = props.waterlevel && msData.yStepSizeMajor
        // Calculate ticks so that at minimum 10 minor ticks appear.
        if (!yStepSize) {
            yStepSize = 10
            if ((yMin || yMin === 0) && (yMax || yMax === 0)) {
                const yDelta = yMax - yMin
                const minScaleTicks = 5
                while (yStepSize > .01 && yDelta < yStepSize * minScaleTicks) {
                    yStepSize /= 10.0
                }
            }
        }
        if (!yStepSizeMajor) {
            yStepSizeMajor = yStepSize * 5
        }
        const yStepPrecision = (yStepSize + '').split('.')[1]?.length || 0

        const unit = props.waterlevel ? (props.msConfig.isSeaSite ? 'm ü. NHN' : 'cm') : 'm\u00B3/s'

        /**
         * Plugin for visualizing units on the top-left corner of DetailChart
         */
        const customTitle = {
            id: 'customTitle',
            beforeLayout: (chart, args, opts) => {
                if (!opts?.x?.display) {
                    return;
                }

                const {ctx} = chart;
                ctx.font = opts.x.font || '12px "Helvetica Neue", Helvetica, Arial, sans-serif'

                const {width} = ctx.measureText(opts.x.text);
                chart.options.layout.padding.right = width * 1.3;
            },
            afterDraw: (chart, args, opts) => {
                const {ctx, chartArea: {top}} = chart;

                if (opts.y.display) {

                    ctx.fillStyle = opts.y.color || Chart.defaults.color
                    ctx.font = opts.y.font || '12px "Helvetica Neue", Helvetica, Arial, sans-serif'
                    ctx.fillText(opts.y.text, opts.y.offsetX || 3, (top + ((opts.y.offsetY * -1) || -15)))
                }
            }
        }

        const data: ChartData<"line"> = {
            // Hint: Colors are used two ways: primary to draw chart data and secondary to render legends and tooltips.
            datasets: [{
                type: 'line',
                label: 'Messung',
                data: levelData,
                fill: false,
                tension: 0.1,
                borderColor: chartColors.measurement,
                borderWidth: 2,
                backgroundColor: chartColors.measurement,
                pointRadius: 0,
                pointHitRadius: 1,
                order: 0
            }, {
                label: "Max",
                data: p90,
                fill: "+1",
                tension: 0.1,
                // Border color is needed for tooltip.
                borderColor: chartColors.p90,
                borderWidth: 0,
                backgroundColor: chartColors.p90,
                pointRadius: 0,
                order: 4
            }, {
                label: "80%",
                data: p80,
                tension: 0.1,
                fill: "+1",
                borderWidth: 0,
                backgroundColor: chartColors.p80,
                pointRadius: 0,
                order: 3
            }, {
                label: "70%",
                data: p70,
                fill: "+1",
                tension: 0.1,
                borderWidth: 0,
                backgroundColor: chartColors.p70,
                pointRadius: 0,
                order: 2
            }, {
                label: "60%",
                data: p60,
                fill: "+1",
                tension: 0.1,
                borderWidth: 0,
                backgroundColor: chartColors.p60,
                pointRadius: 0,
                order: 1
            }, {
                type: 'line',
                label: "Vorhersage",
                data: p50,
                fill: false,
                borderColor: chartColors.p50,
                borderWidth: 2,
                backgroundColor: chartColors.p50,
                pointRadius: 0,
                order: 0
            }, {
                label: "40%",
                data: p40,
                fill: "-1",
                tension: 0.1,
                borderWidth: 0,
                backgroundColor: chartColors.p40,
                pointRadius: 0,
                order: 1
            }, {
                label: "30%",
                data: p30,
                fill: "-1",
                tension: 0.1,
                borderWidth: 0,
                backgroundColor: chartColors.p30,
                pointRadius: 0,
                order: 2
            }, {
                label: "20%",
                data: p20,
                fill: "-1",
                tension: 0.1,
                borderWidth: 0,
                backgroundColor: chartColors.p20,
                pointRadius: 0,
                order: 3
            }, {
                label: "Min",
                data: p10,
                fill: "-1",
                tension: 0.1,
                // Border color is needed for tooltip.
                borderColor: chartColors.p10,
                borderWidth: 0,
                backgroundColor: chartColors.p10,
                pointRadius: 0,
                order: 4
            }]
        }

        /**
         * Initialize annotations for DetailChart. First initializing fixed annotations and then thresholds.
         */
        let annotations: Object = {}
        let legends: Record<string, IToggleableLegend> = {}
        let markLegends: Record<string, IToggleableLegend> = {}

        let nearestThresholdAbove = null
        let nearestThresholdBelow = null
        let hasThresholdBetween = false

        if (props.waterlevel) {
            if (props.msConfig.mh1) {
                annotations['mh1'] = {
                    display: false,
                    drawTime: 'beforeDatasetsDraw',
                    type: 'line',
                    yMin: props.msConfig.mh1,
                    yMax: props.msConfig.mh1,
                    borderColor: chartColors.mh1,
                    borderWidth: 2,
                    label: {
                        content: 'Meldehöhe',
                        enabled: true,
                        backgroundColor: 'rgba(240, 240, 240, 0)',
                        color: chartColors.mh1,
                        yAdjust: -8,
                        yPadding: 0,
                        cornerRadius: 0,
                        position: 'start'
                    }
                }
            }

            if (props.msConfig.mh2) {
                annotations['mh2'] = {
                    display: false,
                    drawTime: 'beforeDatasetsDraw',
                    type: 'line',
                    yMin: props.msConfig.mh2,
                    yMax: props.msConfig.mh2,
                    borderColor: chartColors.mh2,
                    borderWidth: 2,
                    label: {
                        content: 'Meldehöhe',
                        enabled: true,
                        backgroundColor: 'rgba(240, 240, 240, 0)',
                        color: chartColors.mh2,
                        yAdjust: -8,
                        yPadding: 0,
                        cornerRadius: 0,
                        position: 'start'
                    }
                }
            }

            if (props.msConfig.hsw1) {
                annotations['hsw1'] = {
                    display: false,
                    drawTime: 'beforeDatasetsDraw',
                    type: 'line',
                    yMin: props.msConfig.hsw1,
                    yMax: props.msConfig.hsw1,
                    borderColor: chartColors.hsw1.borderColor,
                    borderWidth: 3,
                    borderDash: [5, 5],
                    label: {
                        content: 'SHWM I',
                        enabled: true,
                        backgroundColor: 'rgba(240, 240, 240, 0)',
                        color: chartColors.hsw1.color,
                        yAdjust: -8,
                        yPadding: 0,
                        cornerRadius: 0,
                        position: 'start'
                    }
                }
            }

            if (props.msConfig.hsw2) {
                annotations['hsw2'] = {
                    display: false,
                    drawTime: 'beforeDatasetsDraw',
                    type: 'line',
                    yMin: props.msConfig.hsw2,
                    yMax: props.msConfig.hsw2,
                    borderColor: chartColors.hsw2.borderColor,
                    borderWidth: 3,
                    borderDash: [5, 5],
                    label: {
                        content: 'SHWM II',
                        enabled: true,
                        backgroundColor: 'rgba(240, 240, 240, 0)',
                        color: chartColors.hsw2.color,
                        yAdjust: -8,
                        yPadding: 0,
                        cornerRadius: 0,
                        position: 'start'
                    }
                }
            }

            const markTypes = ['hsw2', 'hsw1', 'mh2', 'mh1']
            markTypes.forEach(element => {
                const markValue = props.msConfig && props.msConfig[element]
                if (markValue) {
                    // Initially show only marks within current min max range.
                    const active = !!(msData.yMin && msData.yMin <= markValue && msData.yMax && markValue <= msData.yMax)
                    if (active) {
                        hasThresholdBetween = true
                    } else if (!hasThresholdBetween && (element !== 'hsw1') && (element !== 'hsw2')) {
                        if ((!msData.yMax || markValue > msData.yMax) && (!nearestThresholdAbove || markValue < props.msConfig[nearestThresholdAbove])) {
                            nearestThresholdAbove = element
                        }
                        if ((!msData.yMin || markValue < msData.yMin) && (!nearestThresholdBelow || markValue > props.msConfig[nearestThresholdBelow])) {
                            nearestThresholdBelow = element
                        }
                    }

                    let description;

                    if (element === 'hsw1' || element === 'hsw2') {
                        description = element === 'hsw1' ? 'Schifff. HW Marke (SHWM I)' : 'Schifff. HW Marke (SHWM II)'
                    } else {
                        if (element === 'mh1' || element === 'mh2') {
                            description = 'Meldehöhe'
                        } else {
                            description = annotations[element].label.content
                        }
                    }

                    annotations[element].display = active
                    markLegends[element] = {
                        active: active,
                        name: element,
                        description: description,
                        color: 'rgba(0, 0, 0, 1)',
                        sorting: null
                    }
                }
            })
        }

        props.thresholds && Object.keys(props.thresholds).forEach(element => {
            const threshold = props.thresholds[element]
            if (threshold && !props.legends[element]?.hiddenDetail) {
                // Initially show only thresholds within current min max range.
                const active = !!(msData.yMin && msData.yMin <= threshold && msData.yMax && threshold <= msData.yMax)
                if (active) {
                    hasThresholdBetween = true
                } else if (!hasThresholdBetween) {
                    if ((!msData.yMax || threshold > msData.yMax) && (!nearestThresholdAbove || threshold < props.thresholds[nearestThresholdAbove])) {
                        nearestThresholdAbove = element
                    }
                    if ((!msData.yMin || threshold < msData.yMin) && (!nearestThresholdBelow || threshold > props.thresholds[nearestThresholdBelow])) {
                        nearestThresholdBelow = element
                    }
                }

                annotations[element] = {
                    display: active,
                    drawTime: 'beforeDatasetsDraw',
                    type: 'line',
                    yMin: threshold,
                    yMax: threshold,
                    borderColor: props.legends[element]?.color,
                    borderWidth: 2,
                    label: {
                        content: props.legends[element]?.description,
                        enabled: true,
                        backgroundColor: 'rgba(240, 240, 240, 0)',
                        color: props.legends[element]?.color,
                        position: 'start', // 'start', 'center', 'end' or '\d%'
                        yAdjust: -8,
                        yPadding: 0,
                        cornerRadius: 0,
                    }
                }

                legends[element] = {...props.legends[element], active}
            }
        })

        // Enable nearest threshold(s).
        if (!hasThresholdBetween) {
            if (nearestThresholdAbove) {
                annotations[nearestThresholdAbove].display = true
                if (['mh1', 'mh2', 'hsw1', 'hsw2'].indexOf(nearestThresholdAbove) >= 0) {
                    markLegends[nearestThresholdAbove].active = true
                } else {
                    legends[nearestThresholdAbove].active = true
                }
            }
            if (nearestThresholdBelow) {
                annotations[nearestThresholdBelow].display = true
                if (['mh1', 'mh2', 'hsw1', 'hsw2'].indexOf(nearestThresholdBelow) >= 0) {
                    markLegends[nearestThresholdBelow].active = true
                } else {
                    legends[nearestThresholdBelow].active = true
                }
            }
        }

        annotations['currentTime'] = {
            drawTime: 'beforeDatasetsDraw',
            type: 'line',
            scaleID: 'x',
            value: levelData[levelData?.length - 1]?.x,
            borderWidth: 7,
            borderColor: chartColors.currentDate.backgroundColor,
            label: {
                rotation: 0,
                content: momentFormatHour(levelData[levelData?.length - 1]?.x),
                enabled: true,
                position: 'center',
                yAdjust: -20,
                backgroundColor: chartColors.currentDate.backgroundColor,
                fontColor: 'black',
                color: chartColors.currentDate.color,
                font: {
                    style: 'normal'
                },
            },
        }

        const yAxis = {
            type: 'linear',
            position: 'left',
            display: displayLeftYAxis,
            beginAtZero: !props.waterlevel && (!yMin || yMin < yStepSizeMajor),
            min: !props.waterlevel && (!yMin || yMin < yStepSizeMajor) ? 0 : undefined,
            // Add 10 cm space below and above chart data.
            grace: yStepSize,
            grid: {
                lineWidth: 3,
                // drawTicks: false,
                color: (ctx) => {
                    // Draw lines only for major ticks.
                    return ctx.tick.major ? chartColors.chartArea.gridLineColor : chartColors.chartArea.backgroundColor
                }
            },
            ticks: {
                includeBounds: false,
                stepSize: yStepSize,
                precision: yStepPrecision,
                align: 'center',
                font: (ctx) => {
                    return ctx.tick?.major ? {weight: 900, size: 12} : {weight: 400 , size: 10.5}
                },
            },
            afterBuildTicks: (scale) => {
                // Set major ticks.
                const precisionFactor = Math.pow(10, yStepPrecision)
                const yStepSizeMajorWeighted = Math.round(yStepSizeMajor * precisionFactor)
                scale.ticks.forEach(t => {
                    t.major = Math.round(t.value * precisionFactor) % yStepSizeMajorWeighted === 0
                })
            }
        }

        const options = {
            interaction: {
                intersect: false
            },
            maintainAspectRatio: true,
            aspectRatio: chartAspectRatio,
            layout: {
                padding: {
                    bottom: 0,
                    top: 25
                }
            },
            animation: false,
            elements: {
                point: {
                    hoverRadius: 0
                }
            },
            responsive: true,
            plugins: {
                customTitle: {
                    y: {
                        display: true,
                        text: '[' + unit + ']',
                        offsetX: 1,
                        offsetY: 10,
                        color: chartColors.axisLabel
                    }
                },
                tooltip: {
                    mode: 'nearestOffset',
                    nearestOffset: 10,
                    axis: 'x',
                    position: 'average',
                    yAlign: 'bottom',
                    // Sort tooltip items by dataset index.
                    itemSort: (ctxA, ctxB) => ctxA.datasetIndex - ctxB.datasetIndex,
                    callbacks: {
                        label: (context) => {
                            let label = context.dataset.label
                            if (label === 'Messung' && (levelData[levelData.length - 1]?.x >= context.raw.x)) {
                                return label + ': ' + formatNumber(context.parsed.y) + ' ' + unit
                            }
                            if ((['Max', 'Min', 'Vorhersage'].indexOf(label) > -1) && levelData[levelData.length - 1]?.x < context.raw.x) {
                                return label + ': ' + formatNumber(context.parsed.y) + ' ' + unit
                            }
                            return null
                        },
                        title: (context) => {
                            if (context[0].raw.x) {
                                return momentFullFormat(context[0].raw.x) + ' Uhr'
                            }
                            return null
                        }
                    }
                },
                datalabels: false,
                legend: false,
                annotation: {
                    annotations: annotations
                },
                decimation: {
                    // Use max 2 data points per rendered pixel keeping min/max peaks.
                    algorithm: 'min-max',
                    threshold: 2
                }
            },
            scales: {
                x: {
                    type: 'time',
                    min: xMin,
                    max: xMax,
                    time: {
                        unit: 'day',
                        displayFormats: {
                            day: chartAspectRatio > 1 ? 'DD.MM.YYYY' : 'DD.MM.',
                        },
                        // Explicitly set parser to support time zones.
                        parser: (x: string|null) => momentParse(x)
                    },
                    grid: {
                        color: chartColors.chartArea.gridLineColor,
                        lineWidth: 3,
                    },
                    ticks: {
                        maxRotation: 0,
                        minRotation: 0,
                        padding: 5,
                        font: {
                            size: 12,
                            weight: 900,
                            align: 'end'
                        },
                    }
                },

                y: yAxis,

                /**
                 * This is the axis on the right side of the chart.
                 * It duplicates the one on the left for better visibility.
                 */
                y2: {
                    ...yAxis,
                    display: true,
                    position: 'right',
                    afterBuildTicks: (scale) => {
                        // Copy the ticks from the y-axis on the left.
                        scale.ticks = scale.chart.scales.y.getTicks()
                        scale.min = scale.chart.scales.y.min
                        scale.max = scale.chart.scales.y.max

                        yAxis.afterBuildTicks(scale)
                    }
                }
            },
            chartArea: {
                backgroundColor: chartColors.chartArea.backgroundColor
            }
        }

        const plugins = [
            {
                id: "chart_accessibility_plugin",
                beforeDraw: (chart) => {
                    if (chart.config.options.chartArea?.backgroundColor) {
                        const ctx = chart.ctx;
                        const chartArea = chart.chartArea;
                        ctx.save();
                        ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
                        ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
                        ctx.restore();
                    }
                },
                afterInit(chart) {
                    const canvas: HTMLCanvasElement = chart?.ctx?.canvas
                    if (!canvas) return;
                    // @ts-ignore
                    canvas.ariaLabel = "Hochwasserbericht von " + props.msConfig.name
                }
            },
            customTitle
        ]

        // Update state and set all args at once.
        setRenderArgs({ annotations, legends, markLegends, options, plugins, data, p10, p20, p30, p40, p50, p60, p70, p80, p90, levelData })
    }, [props.waterlevel, props.msConfig, props.msData, displayLeftYAxis])

    useEffect(() => {
        let mounted = true
        if (loading && props.msConfig && props.msData) {
            setTimeout(() => {
                if (mounted) {
                    updateRenderArgs()
                    setLoading(false)
                }
            }, 200)
        }
        return () => {
            // cleanup function to stop loading when components get unmounted
            mounted = false
        }
    }, [loading, props.msConfig, props.msData, updateRenderArgs])

    useEffect(() => {
        const chart = chartRef.current;
        if (chart) {
            // Modify chart options directly instead of updating state to prevent unnecessary recalculations and flickering.
            chart.options.aspectRatio = chartAspectRatio
            chart.options.scales.x.time.displayFormats.day = chartAspectRatio > 1 ? 'DD.MM.YYYY' : 'DD.MM.'
            chart.update()
        }
    }, [chartAspectRatio])

    // Hide left y axis on small devices.
    useEffect(() => {
        setDisplayLeftYAxis(windowSize.width >= mobileBreakPoint)
        updateRenderArgs()
    }, [windowSize])

    if (loading) {
        return (
            <div className="e-detail-chart__loading-window">
                <p className="e-detail-chart__loading-window__text">Wird geladen ...</p>
            </div>
        )
    }

    const { annotations, legends, markLegends, options, plugins, data, p10, p20, p30, p40, p50, p60, p70, p80, p90, levelData } = renderArgs

    const hasPercentile = (p10.length || p20.length || p30.length || p40.length || p60.length || p70.length || p80.length || p90.length) > 0
    const hasPrediction = hasPercentile || p50.length > 0
    const saveChartImage = (e) => {
        const chart = chartRef.current;
        if (chart) {
            const a = e.target;
            a.href = chart.toBase64Image();
            a.download = props.msConfig.name + '_' + (props.waterlevel ? 'Wasserstand' : 'Abfluss') + '.png';
        }
    }

    return (
        <div className="e-detail-chart">
            <EErrorBoundary errorMsg={"Messwerte konnten nicht geladen werden"}>
                <div className="e-detail-chart__detail-chart-wrapper">
                    <EHelpIcon hash={'pegel-ganglinie'} label="Hilfe zu Pegelganglinien" />
                    <Line ref={chartRef} className="e-detail-chart__detail-chart" data={data} options={options} plugins={plugins} />
                </div>
            </EErrorBoundary>
            <EErrorBoundary errorMsg={"Legende konnte nicht geladen werden"}>
                <div className="e-detail-chart__legend">
                    {levelData.length > 0 &&
                        <div className="e-detail-chart__legend-item">
                            <span className="e-detail-chart__legend-lines">
                                <span style={{ background: chartColors.measurement }}></span>
                            </span>
                            <span className="e-detail-chart__legend-name">Messung</span>
                        </div>
                    }
                    {hasPrediction && (
                        <div className="e-detail-chart__legend-item">
                            <span className="e-detail-chart__legend-lines">
                                {hasPercentile && (
                                    <>
                                        <span style={{ background: chartColors.p90 }}></span>
                                        <span style={{ background: chartColors.p80 }}></span>
                                        <span style={{ background: chartColors.p70 }}></span>
                                        <span style={{ background: chartColors.p60 }}></span>
                                    </>
                                )}
                                <span style={{ background: chartColors.p50 }}></span>
                                {hasPercentile && (
                                    <>
                                        <span style={{ background: chartColors.p40 }}></span>
                                        <span style={{ background: chartColors.p30 }}></span>
                                        <span style={{ background: chartColors.p20 }}></span>
                                        <span style={{ background: chartColors.p10 }}></span>
                                    </>
                                )}
                            </span>
                            <span className="e-detail-chart__legend-name">Vorhersage</span>
                        </div>
                    )}
                    <EHelpIcon hash={'pegel-legende'} label="Hilfe zur Pegellegende" />
                </div>
            </EErrorBoundary>
            {(Object.keys(markLegends).length > 0) &&
                <EErrorBoundary errorMsg={"Legende konnte nicht geladen werden"}>
                    <ELegend legends={markLegends} onActiveToggle={(id, item) => {
                        annotations[id].display = item.active;
                        chartRef.current.update()
                    }}/>
                </EErrorBoundary>
            }
            {(Object.keys(legends).length > 0) &&
                <EErrorBoundary errorMsg={"Legende konnte nicht geladen werden"}>
                    <ELegend legends={legends} onActiveToggle={(id, item) => {
                        annotations[id].display = item.active;
                        chartRef.current.update()
                    }}/>
                </EErrorBoundary>
            }
            {props.children}
            <div className="e-detail-chart__legend">
                <a className="e-detail-chart__legend__save-chart-link" href="#" onClick={saveChartImage}>Als Grafik herunterladen</a>
            </div>
        </div>
    )
};

export default EDetailChart;