
const chartColors = {
    axisLabel: '#262626',
    chartArea: {
        backgroundColor: '#f0f0f0',
        gridLineColor: '#fff'
    },

    measurement: '#3171e0',
    p10: '#ccddff',
    p20: '#99bbff',
    p30: '#3376ff',
    p40: '#0044cc',
    p50: '#ff00ff',
    p60: '#0044cc',
    p70: '#3376ff',
    p80: '#99bbff',
    p90: '#ccddff',

    mh1: '#000',
    mh2: '#000',
    hsw1: {
        color: '#000',
        borderColor: 'rgba(0, 0, 0, 0.7)'
    },
    hsw2: {
        color: '#000',
        borderColor: 'rgba(0, 0, 0, 0.7)'
    },

    currentDate: {
        color: 'rgb(101, 126, 156)',
        backgroundColor: 'rgba(203, 222, 245, 0.5)'
    }
}

export default chartColors;