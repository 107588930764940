import * as proj4 from "proj4leaflet";
import L from "leaflet"

/**
 * Create ETRS89 / UTM zone 32N projection to display stored shape data on map.
 * Resultion and origin are default values and will be overwritten by leaflet.
 * @see https://epsg.io/25832
 */
export const crs = new proj4.CRS(
    "EPSG:25832",
    "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    {
        resolutions: [256, 128],
        origin: [0, 0]
    }
)

/**
 * Unproject method for UTM32 grid.
 */
export const unproject = (coords: [number, number] | [number, number, number]): L.LatLng => crs.unproject(L.point(coords[0], coords[1]))

export const zoomToPointRadius = (zoom: number) => {
    if (zoom >= 10) {
        return 9;
    }
    if (zoom >= 9) {
        return 5;
    }
    return 2;
}

export const checkTouchDevice = () => {
    return (
        ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator['msMaxTouchPoints'] > 0)
    );
}

type TTooltipDirection = 'top' | 'right' | 'bottom' | 'left'

export const calcTooltipDirection = (point: {x: number, y: number}, bounds: {width: number, height: number}, offset?: {x: number, y: number}): TTooltipDirection => {
    const p = {...point}
    if (offset) {
        p.x += offset.x
        p.y += offset.y
    }
    const directions: Array<{direction: TTooltipDirection, distance: number}> = [
        {
            direction: 'bottom',
            distance: p.y
        },
        {
            direction: 'top',
            distance: bounds.height - p.y
        },
        {
            direction: 'right',
            distance: p.x
        },
        {
            direction: 'left',
            distance: bounds.width - p.x
        }
    ]
    return directions.reduce((min, d) => min === null || d.distance < min.distance ? d : min, null).direction
}

export const getIsSmallScreenSize = () => window.innerWidth < 568
