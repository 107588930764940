import "chartjs-adapter-moment";
import annotationPlugin from "chartjs-plugin-annotation";
import moment from "moment-timezone";
import { Chart, Line } from 'react-chartjs-2';
import { IMeasurements } from "../../../models/misc";
import { formatNumber, momentFormatHour, momentParse, validateArray } from "../../../service/chartDataHandler";
import chartColors from "../../../config/chart";
import "./EPreviewChart.scss";
import { BubbleDataPoint, ChartData, ChartOptions, ChartTypeRegistry, LineOptions, Plugin, ScatterDataPoint } from "chart.js";
import { AnyObject } from "chart.js/types/basic";

interface IProps {
    data: IMeasurements;
    className?: string;
    isSeaSite: boolean;
    label: string;
}

const EPreviewChart = (props: IProps) => {
    // Ensure we have data available.
    if (!props.data) {
        return null
    }


    Chart.register(annotationPlugin)

    // Validate predictions/waterlevel data.
    const p10 = validateArray(props.data?.predictions?.p10)
    const p20 = validateArray(props.data?.predictions?.p20)
    const p30 = validateArray(props.data?.predictions?.p30)
    const p40 = validateArray(props.data?.predictions?.p40)
    const p50 = validateArray(props.data?.predictions?.p50)
    const p60 = validateArray(props.data?.predictions?.p60)
    const p70 = validateArray(props.data?.predictions?.p70)
    const p80 = validateArray(props.data?.predictions?.p80)
    const p90 = validateArray(props.data?.predictions?.p90)
    const waterLevelData = validateArray(props.data?.measurements)

    // Define how much space should be drawn between end of data and chart border.
    const xRightPadding = moment.duration(6, 'hours')
    // Define xMin and yMax.
    const xMin = props.data.xMin
    const xMax = props.data.xMax ? +moment(props.data.xMax).add(xRightPadding) : null

    let yMin = props.data.yMin
    let yMax = props.data.yMax

    // Define y step size for chart ticks.
    let yStepSize = props.data.yStepSize
    // Calculate ticks so that at minimum 2 minor ticks appear.
    if (!yStepSize) {
        yStepSize = 10
        if ((yMin || yMin === 0) && (yMax || yMax === 0)) {
            const yDelta = yMax - yMin
            const minScaleTicks = 2
            while (yStepSize > .01 && yDelta < yStepSize * minScaleTicks) {
                yStepSize /= 10.0
            }
        }
    }
    const yStepPrecision = (yStepSize + '').split('.')[1]?.length || 0

    // Define y padding based on step size.
    yMin = yMin ? yMin - yStepSize : null
    yMax = yMax ? yMax + yStepSize : null

    const unit = props.isSeaSite ? 'm ü. NHN' : 'cm'

    /**
     * data configuration for line chart
     */
    const data: ChartData<"line"> = {
        datasets: [{
            type: 'line',
            label: 'Messung',
            data: waterLevelData,
            fill: false,
            tension: 0.1,
            borderColor: chartColors.measurement,
            borderWidth: 2,
            pointRadius: 0,
            order: 0
        }, {
            label: "Min",
            data: p10,
            fill: "+1",
            tension: 0.1,
            borderWidth: 0,
            backgroundColor: chartColors.p10,
            pointRadius: 0,
            order: 4
        }, {
            label: "20%",
            data: p20,
            fill: "+1",
            tension: 0.1,
            borderWidth: 0,
            backgroundColor: chartColors.p20,
            pointRadius: 0,
            order: 3
        }, {
            label: "30%",
            data: p30,
            fill: "+1",
            tension: 0.1,
            borderWidth: 0,
            backgroundColor: chartColors.p30,
            pointRadius: 0,
            order: 2
        }, {
            label: "40%",
            data: p40,
            fill: "+1",
            tension: 0.1,
            borderWidth: 0,
            backgroundColor: chartColors.p40,
            pointRadius: 0,
            order: 1
        }, {
            type: 'line',
            label: "Vorhersage",
            data: p50,
            fill: false,
            borderColor: chartColors.p50,
            borderWidth: 2,
            backgroundColor: chartColors.p50,
            pointRadius: 0,
            order: 0
        }, {
            label: "60%",
            data: p60,
            fill: "-1",
            tension: 0.1,
            borderWidth: 0,
            backgroundColor: chartColors.p60,
            pointRadius: 0,
            order: 1
        }, {
            label: "70%",
            data: p70,
            fill: "-1",
            tension: 0.1,
            borderWidth: 0,
            backgroundColor: chartColors.p70,
            pointRadius: 0,
            order: 2
        }, {
            label: "80%",
            data: p80,
            fill: "-1",
            tension: 0.1,
            borderWidth: 0,
            backgroundColor: chartColors.p80,
            pointRadius: 0,
            order: 3
        }, {
            label: "Max",
            data: p90,
            fill: "-1",
            tension: 0.1,
            borderWidth: 0,
            backgroundColor: chartColors.p90,
            pointRadius: 0,
            order: 4
        }]
    }

    /**
     * setting up options for line chart
     */
    const options: Object = {
        // Disable all events, animations and tooltips to speed up rendering.
        events: [],
        interaction: false,
        animation: false,
        showTooltips: false,

        maintainAspectRatio: false,

        plugins: {
            annotation: {
                annotations: {
                    currentTime: {
                        drawTime: 'afterDatasetsDraw',
                        type: 'line',
                        scaleID: 'x',
                        value: waterLevelData[waterLevelData.length - 1]?.x,
                        borderWidth: 3,
                        borderColor: chartColors.currentDate.backgroundColor,
                        label: {
                            rotation: 0,
                            content: momentFormatHour(waterLevelData[waterLevelData.length - 1]?.x),
                            enabled: true,
                            position: 'start',
                            yAdjust: -9,
                            backgroundColor: chartColors.currentDate.backgroundColor,
                            fontColor: 'black',
                            color: chartColors.currentDate.color,
                            font: {
                                style: 'normal',
                                size: '10.5',
                            },
                            xPadding: 3,
                            yPadding: 2,
                            cornerRadius: 0
                        }
                    }
                }
            },
            datalabels: false,
            legend: false,
            decimation: {
                // Use max 1 data points per rendered pixel using average strategy.
                algorithm: 'lttb',
                threshold: 1
            }
        },
        scales: {
            x: {
                type: 'time',
                min: xMin,
                max: xMax,
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'DD.MM.'
                    },
                    // Explicitly set parser to support time zones.
                    parser: (x: string | null) => momentParse(x)
                },
                grid: {
                    color: chartColors.chartArea.gridLineColor,
                    lineWidth: 3
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    font: {
                        size: 10,
                        weight: 900
                    },
                }
            },
            y: {
                type: 'linear',
                beginAtZero: !(yMin && yMax),
                suggestedMin: yMin,
                suggestedMax: yMax || yStepSize,
                // grace: yStepSize,
                ticks: {
                    // includeBounds: false,
                    stepSize: yStepSize,
                    precision: yStepPrecision,
                    callback: (value, index, values) => {
                        if (index === 0 || index === values.length - 1) {
                            return formatNumber(value) + ' ' + unit
                        }
                    },
                    font: {
                        weight: 500,
                        size: 10.5
                    }
                }
            }
        },
        chartArea: {
            backgroundColor: chartColors.chartArea.backgroundColor
        }
    }

    /**
     * plugin configuration for background setting in chartarea
     */
    const plugin = [{
        id: "chart_accessibility_plugin",
        beforeDraw: (chart) => {
            if (chart.config.options.chartArea?.backgroundColor) {
                var ctx = chart.ctx;
                var chartArea = chart.chartArea;
                ctx.save();
                ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
                ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
                ctx.restore();
            }
        },
        afterInit(chart){
            const canvas: HTMLCanvasElement = chart?.ctx?.canvas
            if (!canvas) return;
            //@ts-ignore
            canvas.ariaLabel = "Preview Chart"
        },
    }]

    return (
        <div className="e-preview-chart">
            <Line data={data} options={options} plugins={plugin} className={props.className} aria-label="Hochwasserbericht der einzelnen Flüsse"></Line>
        </div>
    );
};

export default EPreviewChart;
