import { Grid } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import { useEffect, useState } from 'react';
import { IStatusReportIndex } from "../../../models";
import globalService from "../../../service/globalService";
import "./VWeatherReport.scss";

const VWeatherReport = () => {

    const [statusReport, setStatusReport] = useState<IStatusReportIndex | null>(null)

    const getStatusReports = async () => {
        const res = await globalService.getStatusReports()
        if (res) {
            setStatusReport(res)
        }
    }

    useEffect(() => {
        getStatusReports()
    }, [])

    return (statusReport &&
        <div className="v-status-report container">
            <Grid container spacing={4}>
                <Grid item xs={12} md={9}>
                    <h1 className="v-status-report__headline">Wetterbericht</h1>

                    <div className="v-status-report__root">
                        {statusReport.weather &&
                            <p dangerouslySetInnerHTML={{__html: statusReport.weather}}></p>
                        }
                    </div>

                    <p className="v-status-report__notice">Angaben ohne Gewähr</p>
                </Grid>

                <Grid item xs={12} md={3}>
                    <div className="l-content-box">
                        <Button variant="contained" href={'/'} className="button">Zurück zur Kartenansicht</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default VWeatherReport;