import { Map } from 'leaflet'
import { GeoJSON, GeoJSONProps, useMap } from "react-leaflet"
import { checkTouchDevice, unproject } from "../../../utils/mapUtils"
import './EMap__Polygon.scss'

/**
 * Polygon Component Leaflet Layer
 * used to display and handle alertRegions
 */
interface IProps {
    data: GeoJSONProps['data']
    fillColor?: string
    onClick?: (e: any, map: Map) => void
}

const isTouchDevice = checkTouchDevice();

function getHoverColor(fillColor: string) {
    return fillColor.toLowerCase().replace(/([0-9a-f]{2})/g, (_, p1) => {
        const val = parseInt(p1, 16);
        const newVal = Math.round(Math.max(0, val - (val * 0.1)));
        return newVal.toString(16).padStart(2, '0');
    });
}

const EMap__AlertRegionPolygon = (props: IProps) => {
    const map = useMap()
    
    const opts = {
        data: props.data,
        coordsToLatLng: unproject,
        noClip: true,
        shadowPane: '',
        interactive: !!props.onClick,
        className: 'polygon' + (props.onClick ? ' polygon--has-data' : ''),
        style: {
            fillColor: props.fillColor || '#fff',
            color: '#333',
            fillOpacity: 1,
            weight: .5,
        },
        eventHandlers:
            props.onClick ? {
                click(e) {
                    e.originalEvent._stopped = true;

                    props.onClick(e, map);
                },
                mousemove(e) {
                    if (isTouchDevice) {
                        return;
                    }
                    
                    if(e.layer) {
                        e.originalEvent._stopped = true;
                        
                        if(e.layer.options.fillColor === e.layer.options.style.fillColor) {
                            e.layer.options.fillColor = getHoverColor(e.layer.options.style.fillColor);
                            e.layer.options.weight = 1.3;
                        }

                        e.layer.redraw();
                    }
                },
                mouseout(e) {
                    if (isTouchDevice) {
                        return;
                    }

                    e.layer.options.fillColor = e.layer.options.style.fillColor;
                    e.layer.options.weight = e.layer.options.style.weight;

                    e.layer.redraw();
                }
            } : null,
    }

    return <GeoJSON {...opts}/>
}

export default EMap__AlertRegionPolygon