
/**
 * Simple map where values expire at given time.
 */
export default class ExpireCache<T> {

    private store: Map<string, {expire: number, value: T}> = new Map<string, {expire: number, value: T}>();

    public get(key: string): any {
        let entry: {expire: number, value: T} = this.store.get(key)
        if (entry && entry.expire < (new Date()).getTime()) {
            this.store.delete(key)
            entry = undefined
        }
        return entry?.value
    }

    public set(key: string, value: T, expire: number) {
        this.store.set(key, {expire, value})
    }
}
