import './EMap__Polygon.scss'
import './EMap__NeighbourCountryPolygon.scss'
import { GeoJSON, GeoJSONProps, Tooltip } from "react-leaflet"
import { checkTouchDevice, unproject } from "../../../utils/mapUtils"

/**
 * Polygon Component Leaflet Layer
 * used to display and handle neighbourcountries
 */
interface IProps {
    data: GeoJSONProps['data']
    name: string
    url: string
}

const isTouchDevice = checkTouchDevice();

const EMap__NeighbourCountryPolygon = (props: IProps) => {
    const customHandlers = {
        // Because react leaflet seems to ignore Tooltip.position prop, we have to set position on open.
        tooltipopen(e) {
            const data: any = props.data
            if (data.properties.LABEL_X && data.properties.LABEL_Y) {
                // e.tooltip.setOpacity(1)
                e.tooltip.setLatLng(unproject([data.properties.LABEL_X, data.properties.LABEL_Y]))
                e.tooltip.update()
            }
        },
        mouseover(e) {
            if(isTouchDevice) {
                return;
            }

            if(e.originalEvent.target.tagName === 'CANVAS') {
                e.originalEvent._stopped = true;

                e.layer.options.weight = 2;
                e.layer.redraw();
            }
        },
        mouseout(e) {
            if(isTouchDevice) {
                return;
            }

            if(e.originalEvent.target.tagName === 'CANVAS') {
                e.layer.options.weight = e.layer.options.style.weight;
                e.layer.redraw();
            }
        }
    }
    return (
        <GeoJSON
            data={props.data}
            coordsToLatLng={unproject}
            // noClip={true}
            // shadowPane=""
            style={{
                weight: 1,
                fillColor: 'white',
                color: '#999',
                fillOpacity: 1,
                className: 'polygon e-map__neighbour-countries'
            }}
            interactive={true}
            eventHandlers={customHandlers}
        >
            <Tooltip
                opacity={1}
                pane="neighbourcountries"
                className="e-map__neighbour-countries__tooltip"
                direction="center"
                interactive={true}
                // This is not working at all.
                // position={[0, 0]}
                permanent
            >
                <a href={props.url} target="_blank" rel="noreferrer">{props.name}</a>
            </Tooltip>
        </GeoJSON>
    )
}

export default EMap__NeighbourCountryPolygon