import { localService, TLocalStorageName } from '../service/storageHandler';

const shapefile = require("shapefile");

type TAreaType = 'alertRegions' | 'riverLines' | 'stations' | 'lakes' | 'neighbourcountry' | 'riverAreas'
type TAreaHashType = 'alertRegionsHash' | 'riverLinesHash' | 'stationsHash' | 'riverAreasHash' | 'lakesHash' | 'neighbourcountryHash'

export const areaType = {
    alertRegions: 'alertRegions',
    riverLines: 'riverLines',
    stations: 'stations',
    lakes: 'lakes',
    riverAreas: 'riverAreas',
    neighbourcountry: 'neighbourcountry'
}

export const areaHashType = {
    alertRegions: 'alertRegionsHash',
    riverLines: 'riverLinesHash',
    stations: 'stationsHash',
    lake: 'lakesHash',
    riverAreas: 'riverAreasHash',
    neighbourcountry: 'neighbourcountryHash'
}

const sharedPath = '/static/shared'

const filePaths = {
    alertRegions: sharedPath + "/shp/alertregion/warnregionen.shp",
    riverLines: sharedPath + "/shp/riverline/gewaesser.shp",
    riverAreas: sharedPath + "/shp/riverarea/flussgebiete.shp",
    lakes: sharedPath + "/shp/lake/flaechengewaesser.shp",
    stations: sharedPath + "/shp/measurementsite/pegelorte.shp",
    neighbourcountry: sharedPath + "/shp/neighbourcountry/nachbarland.shp"
}

/**
 * fetch Data from files
 * @param filePath
 */
const fetchShapefile = async (name: TLocalStorageName, setState: any, hashName: TAreaHashType, hashValue: string): Promise<void> => {
    let res: any[] = []
    const source = await shapefile.open(filePaths[name], undefined, { encoding: 'UTF-8' })
    source?.read().then(function next(result) {
        if (result.done) {
            setState(res)
            localService.set(name, res)
            localService.set(hashName, hashValue)
            return;
        }
        else {
            res.push(result.value)
            return source?.read().then(next);
        }
    }).catch(error => console.error(error.stack));
}

export const getShapeFileData = async (name: TAreaType, setState:any, hashName: TAreaHashType, hashValue: string ) => {
    const storageResult: any[] | undefined = localService.get(name)
    const hashResult: string | undefined = localService.get(hashName)
    if (storageResult && (hashResult === hashValue)) return setState(storageResult)
    else return await fetchShapefile(name, setState, hashName, hashValue)
}