import { memo, useEffect, useState } from "react";
import AlertIcon from "../../../assets/images/icon-alert.svg";
import InfoIcon from "../../../assets/images/icon-info.svg";
import IConfig from "../../../models/config";
import IIndex, { IAlertRegion } from "../../../models/index";
import ErrorBoundary from "../../elements/error-boundary/EErrorBoundary";
import EHelpIcon from "../../elements/help-icon/EHelpIcon";
import EMessage from "../../elements/message/EMessage";
import EStatusReport from "../../elements/status-report/EStatusReport";
import MDetailboxWaterlevels from "../detail-box-waterlevels/MDetailboxWaterlevels";
import "./MDetailbox.scss";
import { useStoreState } from "easy-peasy";
import store, { IState } from "../../../store/store";
import { useMediaQuery } from "@mui/material";
import EAccordeon from "../../elements/accordeon/EAccordeon";
import { Link } from "react-router-dom";
import { momentFullFormat } from "../../../service/chartDataHandler";

interface IWarnAreaProps {
  index: IIndex;
  config: IConfig;
}

const MWarnAreaInfos = (props: IWarnAreaProps) => {
  const [warnAreaName, setWarnAreaName] = useState("");
  const [warnAreaClass, setWarnAreaClass] = useState("");
  const [warnAreaDescription, setWarnAreaDescription] = useState("");
  const [preWarnAreaDescription, setPreWarnAreaDescription] = useState<
    string | undefined
  >(undefined);
  const [preAlert, setPreAlert] = useState(false);
  const [warnArea, setWarnArea] = useState<IAlertRegion>(undefined);
  const [warnAreaColor, setWarnAreaColor] = useState<string>("");
  const warnAreaID = useStoreState<IState>(
    (state) => state.selectedWarnArea.area
  );
  const [timeStamp, setTimeStamp] = useState("");

  useEffect(() => {
    setWarnArea(props.index.alertregions[warnAreaID]);
    if (!warnArea) {
      setWarnAreaName("");
    } else {
      setWarnAreaName(warnArea.name);
      setTimeStamp(momentFullFormat(warnArea.importedAt));
      setWarnAreaColor(getWarnAreaColor(warnAreaID));
      setWarnAreaClass(props.config.alertclasses[warnArea.alertClassId].name);
      setWarnAreaDescription(
        props.config.alertclasses[warnArea.alertClassId].description
      );
      setPreAlert(props.index.alertregions[warnAreaID]?.preAlert ?? false);
      setPreWarnAreaDescription(
        props.config.alertclasses[warnArea.alertClassId].preWarnDescription ??
          undefined
      );
    }
  }, [
    warnArea,
    warnAreaID,
    props.index.alertregions,
    props.config.alertclasses,
  ]);

  // get warnareacolor hatch if prealert
  const getWarnAreaColor = (id: number) => {
    const initAlertRegion = props.index.alertregions[id];
    if (initAlertRegion) {
      if (initAlertRegion.preAlert) {
        return `url(#Hatch_${initAlertRegion.alertClassId})`;
      }
      return props.config.alertclasses[initAlertRegion.alertClassId].color;
    }
    return "#93C66A";
  };

  return (
    <div className="m-warnarea-infos">
      {warnArea ? (
        <div className="m-warnarea-infos__box">
          <svg className="m-warnarea-infos__color-column">
            <rect width="100%" height="100%" fill={warnAreaColor} />
          </svg>
          <div className="m-warnarea-infos__body">
            <EAccordeon externalIsOpen={true}>
              {{
                heading: (
                  <div className="m-warnarea-infos__heading-wrap">
                    <h5 className="m-warnarea-infos__heading">
                      {warnAreaName}
                    </h5>
                    {timeStamp && (
                      <p className="m-warnarea-infos__subheading">
                        Warnung vom {timeStamp} Uhr
                      </p>
                    )}
                  </div>
                ),
                content: (
                  <>
                    <p>Gefährdung: {warnAreaClass}</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          preAlert && preWarnAreaDescription !== undefined
                            ? preWarnAreaDescription
                            : warnAreaDescription,
                      }}
                    ></div>
                    <a
                      className="m-warnarea-infos__link"
                      href={`/warnungen#${warnAreaID}`}
                    >
                      Zur Warnungsübersicht
                    </a>
                  </>
                ),
              }}
            </EAccordeon>
          </div>
        </div>
      ) : (
        <EGeneralWarnings
          index={props.index}
          config={props.config}
        ></EGeneralWarnings>
      )}
    </div>
  );
};

interface IGeneralWarningProps {
  index: IIndex;
  config: IConfig;
}

const EGeneralWarnings = (props: IGeneralWarningProps) => {
  const [highestWarningRegion, setHighestWarningRegion] =
    useState<IAlertRegion>(undefined);
  const warnAreaID = useStoreState<IState>(
    (state) => state.selectedWarnArea.area
  );

  const getHighestWarning = async (index: IIndex) => {
    let highest = -1;
    let highestRegion = undefined;
    Object.values(index.alertregions).forEach(async (region) => {
      if (region.alertClassId > highest) {
        highest = region.alertClassId;
        highestRegion = region;
      }
    });
    setHighestWarningRegion(highestRegion);
  };

  useEffect(() => {
    getHighestWarning(props.index);
  }, [props.index]);

  return (
    <div className={"e-message"}>
      {highestWarningRegion && (
        <>
          <div
            className="e-message__icon-wrap"
            style={{
              backgroundColor:
                props.config.alertclasses[highestWarningRegion.alertClassId]
                  .color,
            }}
          ></div>
          <div className="e-message__content-wrap">
            <div className="e-message__content-inner">
              <p>
                Hier geht es zur Übersicht aller Warngebiete, aufgelistet mit
                der jeweils aktuellen Warnstufe.
              </p>
              <a
                className="m-warnarea-infos__link"
                href={`/warnungen#${warnAreaID}`}
              >
                Zur Warnungsübersicht
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

interface IProps {
  config: IConfig;
  index: IIndex;
  detailboxOpen: boolean;
  setDetailboxOpen: (isOpen: boolean) => void;
}

const MDetailbox = (props: IProps) => {
  /**
   * useMediaQuery Hook runs twice if ssr is enabled, we only use it clientside so it can be safely disabled.
   * this also prevents console errors
   */
  const isMobile = useMediaQuery(`(max-width: 760px)`);

  const warnAreaID = useStoreState<IState>(
    (state) => state.selectedWarnArea.area
  );

  const toggleOpenState = () => {
    if (props.detailboxOpen) {
      // called when the box is open, means it gets closed
      // reset the active alertregion
      store.dispatch.selectedWarnArea.setArea(undefined);
    }
    props.setDetailboxOpen(!props.detailboxOpen);
  };

  return (
    <div
      className={
        "m-detailbox" + (props.detailboxOpen ? " m-detailbox--open" : "")
      }
    >
      <div className="m-detailbox__head">
        <h3 className="m-detailbox__heading">
          Aktueller Bericht
          <EHelpIcon hash={"bericht"} label="Erläuterungen zum aktuellen Bericht" />
        </h3>
        <div
          className="m-detailbox__toggle-button"
          tabIndex={1}
          onClick={toggleOpenState}
          onKeyPress={toggleOpenState}
        >
          <span className={"m-detailbox__toggle-button__inner"}></span>
          <img
            alt={"Info Icon"}
            aria-label="Klicke zum Aufklappen"
            src={props.index?.statusReport?.isInfo ? InfoIcon : AlertIcon}
            className={"m-detailbox__toggle-button__info-icon"}
          />
        </div>
      </div>

      <div className="m-detailbox__body">
        {/*<h4 className="m-detailbox__accordion__heading">*/}
        {/*  Allgemeine Hinweise*/}
        {/*</h4>*/}
        {props.index?.statusReport && (
          <div className="m-detailbox__status-report">
            <ErrorBoundary
              errorMsg={"Hochwasserbericht konnte nicht geladen werden"}
            >
              <EStatusReport
                statusReport={props.index.statusReport}
                isDetailView={false}
                isAccordeonCollapsed={warnAreaID !== undefined}
              />
            </ErrorBoundary>
          </div>
        )}
        <div className="m-detailbox__message__head">
          <h3 className="m-detailbox__message__heading">
            Aktuelle Warnung
            <EHelpIcon hash={"warnungen"} label="Erläuterungen zu den Warnungen" />
          </h3>
        </div>
        <MWarnAreaInfos index={props.index} config={props.config} />
        {props.index?.hint && (
          <div className="m-detailbox__message">
            <ErrorBoundary errorMsg={"Hinweise sind nicht Verfügbar"}>
              <EMessage hint={props.index.hint} isDetailView={false} />
            </ErrorBoundary>
          </div>
        )}
        <div className="m-detailbox__content">
          <h3>Pegelstände</h3>
          {props.config && props.detailboxOpen && (
            <ErrorBoundary errorMsg={"Details konnten nicht geladen werden"}>
              <MDetailboxWaterlevels
                index={props.index}
                config={props.config}
              />
            </ErrorBoundary>
          )}
        </div>
      </div>
    </div>
  );
};

export default MDetailbox;

export const MemoizedMDetailbox = memo(MDetailbox);
