import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IconAlert from "../../../assets/images/icon-alert.svg";
import IconInfo from "../../../assets/images/icon-info.svg";
import routes from "../../../config/routes";
import { IStatusReport } from "../../../models";
import { IRiverArea } from "../../../models/config";
import { momentFullFormat } from "../../../service/chartDataHandler";
import EHelpIcon from "../help-icon/EHelpIcon";
import "./EStatusReport.scss";
import EAccordeon from "../accordeon/EAccordeon";

interface IProps {
  isDetailView: boolean;
  statusReport: IStatusReport[] | IStatusReport;
  riverAreaConfig?: Record<number, IRiverArea> | null;
  showInfo?: boolean;
  isAccordeonCollapsed?: boolean;
}

const isWarn = (statusReports: IStatusReport[]) => {
  return statusReports.some((report) => report.isInfo === false);
};

interface ListProps extends IProps {
  statusReport: IStatusReport[];
  areaName: string;
}

const StatusReportList = (props: ListProps) => {
  return (
    <>
      <div
        className={
          "e-status-report__icon" +
          (isWarn(props.statusReport) ? " is-report" : "")
        }
      >
        <img
          alt={"Hochwasserbericht"}
          src={isWarn(props.statusReport) ? IconAlert : IconInfo}
        />
      </div>
      <div className={"e-status-report__body"}>
        <div className={"e-status-report__items"}>
          {props.statusReport.map((report, i) => {
            return (
              <div className="e-status-report__item" key={i}>
                <p className="h5">
                  {props.statusReport.length > 1
                    ? props.riverAreaConfig[report.riverAreaId].rivers[
                        report.riverParameter
                      ].name
                    : props.areaName}
                  {i === 0 && <EHelpIcon hash={"bericht"} label="Hinweise zum Hochwasserbericht"/>}
                </p>
                {report.date && (
                  <p className="e-status-report__subheadline">
                    {report.isInfo ? "Infobericht" : "Hochwasserbericht"} vom{" "}
                    {momentFullFormat(report.date)} Uhr
                  </p>
                )}
                {report.info && !props.isDetailView && (
                  <div
                    className="e-status-report__content"
                    dangerouslySetInnerHTML={{ __html: report.info }}
                  ></div>
                )}
              </div>
            );
          })}
        </div>
        <Link className="e-status-report__link" to={routes.statusReport.path}>
          Zum Bericht
        </Link>
      </div>
    </>
  );
};
 
interface SingleProps extends IProps {
  statusReport: IStatusReport;
  areaName: string;
}

const StatusReportSingle = (props: SingleProps) => {
  return (
    <>
      <div
        className={
          "e-status-report__icon" +
          (!props.statusReport.isInfo ? " is-report" : "")
        }
      >
        <img
          alt={"Hochwasserbericht"}
          src={props.statusReport.isInfo ? IconInfo : IconAlert}
        />
      </div>
      <div className="e-status-report__body">
        <>
          <EAccordeon externalIsOpen={false}>
            {{
              heading: (
                <>
                  <p className="h5">{props.areaName}</p>
                  {props.statusReport.date && (
                    <p className="e-status-report__subheadline">
                      {props.statusReport.isInfo
                        ? "Infobericht"
                        : "Hochwasserbericht"}{" "}
                      vom {momentFullFormat(props.statusReport.date)} Uhr
                    </p>
                  )}
                </>
              ),
              content: (
                <>
                  {props.statusReport.info && (
                    <div
                      className="e-status-report__content"
                      dangerouslySetInnerHTML={{
                        __html: props.statusReport.info,
                      }}
                    ></div>
                  )}
                  <Link
                    className="e-status-report__link"
                    to={routes.statusReport.path}
                  >
                    Zum Bericht
                  </Link>
                </>
              ),
            }}
          </EAccordeon>
        </>
      </div>
    </>
  );
};

const EStatusReport = (props: IProps) => {
  const [areaName, setAreaName] = useState("");
  const parentAreaName = "Rheinland-Pfalz";

  const getAreaName = (riverArea: IRiverArea) => {
    let areaName = "";
    if (Array.isArray(props.statusReport)) {
      if (props.statusReport[0].riverParameter) {
        areaName = riverArea.rivers[props.statusReport[0].riverParameter].name;
      } else {
        areaName = riverArea.name;
      }
    } else {
      if (props.statusReport.riverParameter) {
        areaName = riverArea.rivers[props.statusReport.riverParameter].name;
      } else {
        areaName = riverArea.name;
      }
    }
    return areaName;
  };

  useEffect(() => {
    let areaName = parentAreaName;

    let riverAreaId: number | null = null;

    if (Array.isArray(props.statusReport)) {
      riverAreaId = props.statusReport[0]?.riverAreaId;
    } else {
      riverAreaId = props.statusReport?.riverAreaId;
    }

    if (props.riverAreaConfig && riverAreaId && props.isDetailView) {
      const riverArea: IRiverArea | null = props.riverAreaConfig[riverAreaId];

      areaName = getAreaName(riverArea);
    }

    setAreaName(areaName);
  }, [props.riverAreaConfig, props.statusReport, props.isDetailView]);

  return (
    props.statusReport && (
      <div
        className={"e-status-report" + (props.isDetailView ? " detail" : "")}
      >
        {Array.isArray(props.statusReport) ? (
          <StatusReportList
            statusReport={props.statusReport}
            areaName={areaName}
            isDetailView={props.isDetailView}
            riverAreaConfig={props.riverAreaConfig}
          />
        ) : (
          <StatusReportSingle
            statusReport={props.statusReport}
            areaName={areaName}
            isDetailView={props.isDetailView}
            riverAreaConfig={props.riverAreaConfig}
            isAccordeonCollapsed={props.isAccordeonCollapsed}
          />
        )}
      </div>
    )
  );
};

export default EStatusReport;
