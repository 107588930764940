import "./VWarnings.scss";
import { useMemo, useState } from "react";
import globalService from "../../../service/globalService";
import IIndex from "../../../models";
import IConfig, { IAlertRegion } from "../../../models/config";
import indexRequestHandler from "../../../service/globalService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import EHelpIcon from "../../elements/help-icon/EHelpIcon";
import Hatch from "../../../utils/Hatch";
import { momentFullFormat } from "../../../service/chartDataHandler";

const VWarningsPage = () => {
  const [index, setIndex] = useState<IIndex | null>(null);
  const [config, setConfig] = useState<IConfig | null>(null);

  const getIndex = async () => {
    const res = await indexRequestHandler.getIndex();
    if (res) {
      setIndex(res);
    }
  };

  const getConfig = async () => {
    const res = await globalService.getConfig();
    if (res) {
      setConfig(res);
    }
  };

  useMemo(async () => {
    await getConfig();
    await getIndex();
  }, []);

  const getWarnColor = (alertId: string) => {
    const initAlertRegion = index.alertregions[alertId];
    if (initAlertRegion) {
      if (initAlertRegion.preAlert) {
        return `url(#Hatch_${initAlertRegion.alertClassId})`;
      }
      return config.alertclasses[initAlertRegion.alertClassId].color;
    }
    return "#93C66A";
  }

  interface IRiverAreaWarningRowProps {
    alertRegion: IAlertRegion;
    importedAt?: string;
    alertId: string;
  }

  const RiverAreaWarningRow = (props: IRiverAreaWarningRowProps) => {
    const riverAreaName = props.alertRegion.name;
    const [timestamp] = useState(
      momentFullFormat(props.importedAt)
    );

    const alert = config.alertclasses[props.alertRegion.alertClassId];
    const color = getWarnColor(props.alertId);

    return (
      <TableRow className="v-warnings__table-row">
        <TableCell className="v-warnings__table-cell">
          {riverAreaName}
        </TableCell>
        <TableCell
          className="v-warnings__table-cell--color"
        >
          <svg>
             <rect width="100%" height="100%" fill={color} /> 
          </svg>
        </TableCell>
        <TableCell className="v-warnings__table-cell">{alert.name}</TableCell>
        <TableCell className="v-warnings__table-cell">
          {timestamp} Uhr
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className="container v-warnings">
      {config && index && (
        <>
          <div className="e-map__hatches-prerender">
            {config.alertclasses &&
              Object.keys(config.alertclasses).map((id) => {
                const alertClass = config.alertclasses[id];
                return (
                  <Hatch
                    color={alertClass.color}
                    stripeColor={alertClass.stripeColor}
                    alertClassId={id}
                    key={id}
                  />
                );
              })}
          </div>
          <h1>
            Warnungen <EHelpIcon hash="warnungen" label="Erläuterungen zu den Warnungen"/>
          </h1>
          <p className="v-warnings__description">
            Ist für eine Warnregion aufgrund von Modellberechnungen oder
            Pegelmessungen ein Hochwasser vorhersagbar, dann erhält dieses
            Warngebiet eine von grün abweichende Warnfarbe. Nachfolgend sind
            alle Warngebiete mit der jeweils aktuellen Warnstufe aufgelistet.
            <br /> <br />
            Weitere aktuelle Informationen durch Auswahl einer Warnregion auf
            der <a href="/">Warnkarte</a>.
          </p>
          <TableContainer className="v-warnings__table">
            <Table>
              <TableHead className="v-warnings__table-head">
                <TableRow className="v-warnings__table-row">
                  <TableCell className="v-warnings__table-cell heading">
                    Warnregion
                  </TableCell>
                  <TableCell className="v-warnings__table-cell heading">
                    Warnstufe
                  </TableCell>
                  <TableCell className="v-warnings__table-cell heading">
                    Bedeutung
                  </TableCell>
                  <TableCell className="v-warnings__table-cell heading">
                    Gültig ab
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(config.alertregions).map((key, i) => {
                  return (
                    <RiverAreaWarningRow
                      key={i}
                      alertId={key}
                      alertRegion={config.alertregions[key]}
                      importedAt={index?.alertregions[key]?.importedAt}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default VWarningsPage;
