import {useState, useEffect} from 'react';
import "./VDynamicDetail.scss";
import Grid from '@material-ui/core/Grid';
import globalService from "../../../service/globalService";
import {useParams} from "react-router-dom";
import V404 from "../404-page/V404";
import IConfig, {IMeasurementSiteConfig, IRiver, IRiverArea} from "../../../models/config";
import MDetailMeasurementSites from "../../modules/detail-measurementsites/MDetailMeasurementSites";
import MDetailMenu from "../../modules/detail-menu/MDetailMenu";
import ErrorBoundary from "../../elements/error-boundary/EErrorBoundary";
import EPeaklevels from "../../elements/peaklevels/EPeaklevels";
import MRiverAreaDetailBox from "../../modules/riverarea-detailbox/MRiverAreaDetailBox";
import {IDetailInfo, IDetailRiverArea} from "../../../models/misc";
import EStatusReport from "../../elements/status-report/EStatusReport";
import EMessage from "../../elements/message/EMessage";
import EHelpIcon from "../../elements/help-icon/EHelpIcon";
import { CircularProgress } from '@material-ui/core';


interface IProps {
}

const VDynamicDetail = (props: IProps) => {

    const [config, setConfig] = useState<IConfig>(null)
    const [riverAreaConfig, setRiverAreaConfig] = useState<IRiverArea>(null)
    const [riverAreaDetails, setRiverAreaDetails] = useState<IDetailRiverArea>(null)
    const [riverConfig, setRiverConfig] = useState<IRiver>(null)
    const [msConfig, setMsConfig] = useState<IMeasurementSiteConfig>(null)
    const [msData, setMsData] = useState<IDetailInfo>(null)

    const [is404Page, setIs404Page] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(true);

    const {riverAreaSlug, msSlug} = useParams<{ riverAreaSlug: string, msSlug?: string }>()

    const initRiverAreaDetails = async (riverAreaId: number) => {
        const res = await globalService.getRiverAreaSortedDetails(riverAreaId)
        if (res) setRiverAreaDetails(res)
    }

    const initMeasurementSiteDetails = async (number: number) => {
        const res = await globalService.getMeasurementSiteDetails(number)
        if (res) setMsData(res)
    }

    const initConfigAndDetails = async () => {
        const config: IConfig = await globalService.getConfig()
        if (config) {
            setConfig(config)
            /**
             * check if url slugs are present in config slugs
             */
            const raSlugData = riverAreaSlug && config.slugs[riverAreaSlug]
            let slugFound: boolean = false
            if (raSlugData) {
                setRiverAreaConfig(config.riverareas[raSlugData.id])

                const msSlugData = msSlug && raSlugData.measurementsites[msSlug]
                if (msSlugData) {
                    setRiverAreaDetails(null)
                    const msConfig = config.measurementsite[msSlugData.ms_number]
                    setMsConfig(msConfig)
                    setRiverConfig(config.riverareas[raSlugData.id].rivers[msConfig.rivers[0]])
                    await initMeasurementSiteDetails(msSlugData.ms_number)
                    slugFound = true
                } else if (!msSlug) {
                    setRiverConfig(null)
                    setMsConfig(null)
                    setMsData(null)
                    await initRiverAreaDetails(raSlugData.id)
                    slugFound = true
                }
            }
            if (!slugFound) {
                console.warn('url slug not found')
                setIs404Page(true)
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        initConfigAndDetails()
    }, [riverAreaSlug, msSlug])

    if (isLoading) {
        return <div className='loading-screen'>
            <CircularProgress />
        </div>;
    }    

    return (
        <div className="v-detail-measurement">
            {is404Page ? <V404/> : (
                <div className="container">
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={9}>
                            <div className="content-box">
                                {msConfig &&
                                    <h1>Pegel {msConfig.name}{riverConfig ? ` / ${riverConfig.name}` : ''}</h1>
                                }
                                {riverAreaDetails && riverAreaConfig &&
                                    <h1>Flussgebiet {riverAreaConfig.name}</h1>
                                }

                                {/* Status reports and hints. */}
                                {msData?.statusReport &&
                                    <ErrorBoundary errorMsg={'Hochwasserbericht nicht verfügbar'}>
                                        <EStatusReport
                                            statusReport={msData.statusReport}
                                            isDetailView={true}
                                            riverAreaConfig={config.riverareas}
                                        />
                                    </ErrorBoundary>
                                }
                                {msData?.hint &&
                                    <ErrorBoundary errorMsg={'Hochwasserbericht nicht verfügbar'}>
                                        <EMessage hint={msData.hint} isDetailView={true}/>
                                    </ErrorBoundary>
                                }
                                {riverAreaDetails?.statusReport &&
                                    <ErrorBoundary errorMsg={'Hochwasserbericht nicht verfügbar'}>
                                        <EStatusReport
                                            statusReport={riverAreaDetails.statusReport}
                                            isDetailView={true}
                                            riverAreaConfig={config.riverareas}
                                        />
                                    </ErrorBoundary>
                                }
                                {riverAreaDetails?.hint &&
                                    <ErrorBoundary errorMsg={'Hochwasserbericht nicht verfügbar'}>
                                        <EMessage hint={riverAreaDetails.hint} isDetailView={true}/>
                                    </ErrorBoundary>
                                }

                                {(config && msData && msSlug) ? (
                                    /* Measurement site details. */
                                    <ErrorBoundary errorMsg={"Details konnten nicht geladen werden"}>
                                        <MDetailMeasurementSites
                                            msConfig={config.measurementsite[msConfig.number]}
                                            msNumber={msConfig.number}
                                            measurementSitesData={msData}
                                            legends={config.legends}
                                            operator={config.operators[msConfig.number]}
                                            getMeasurementPointDetails={initMeasurementSiteDetails}
                                        />
                                    </ErrorBoundary>
                                ) : (
                                    /* River area details. */
                                    <>
                                        {riverAreaDetails?.peaklevel &&
                                            <>
                                                <h2>Höchststände<EHelpIcon hash={'hoechststaende'} label="Hinweise zur Übersicht der Höchststände"/></h2>
                                                <ErrorBoundary>
                                                    <EPeaklevels
                                                        data={riverAreaDetails}
                                                        slug={config.slugs[riverAreaSlug]}
                                                        currentRiverArea={riverAreaSlug}
                                                        riverArea={riverAreaConfig}
                                                        measurementSites={config.measurementsite}
                                                    />
                                                </ErrorBoundary>
                                            </>
                                        }
                                        {riverAreaDetails?.rivers &&
                                            <ErrorBoundary>
                                                <MRiverAreaDetailBox
                                                    riverAreaMSSlugs={config.slugs[riverAreaSlug].measurementsites}
                                                    riverAreaRivers={riverAreaConfig.rivers}
                                                    rivers={riverAreaDetails.rivers}
                                                    riverAreaSlugKey={riverAreaSlug}
                                                    measurementSites={config.measurementsite}
                                                />
                                            </ErrorBoundary>
                                        }
                                    </>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            {config &&
                                <ErrorBoundary errorMsg={"Menü konnte nicht geladen werden"}>
                                    <MDetailMenu
                                        config={config}
                                        riverAreaSlug={riverAreaSlug}
                                        msSlug={msSlug}
                                    />
                                </ErrorBoundary>
                            }
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    )
}

export default VDynamicDetail;