import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import IConfig from "../../../models/config";
import EHelpIcon from "../../elements/help-icon/EHelpIcon";
import "./MDetailMenu.scss";

interface IProps {
    config: IConfig | undefined;
    riverAreaSlug: string,
    msSlug?: string
}

const MDetailMenu = (props: IProps) => {

    const [riverAreaId, setRiverAreaId] = useState<number>(null);
    const [riverAreaSlug, setRiverAreaSlug] = useState<string>(props.riverAreaSlug);
    const [riverParameter, setRiverParameter] = useState<string>(null);
    const [msSlug, setMsSlug] = useState(props.msSlug);

    const history = useHistory();

    const setActiveSelectFieldsAndRivers = () => {
        setRiverAreaSlug(props.riverAreaSlug)
        const slugData = props.config?.slugs[props.riverAreaSlug]
        setRiverAreaId(slugData?.id)
        setMsSlug(props.msSlug)
        const riverParameter = props.msSlug && slugData ? props.config?.measurementsite[slugData.measurementsites[props.msSlug]?.ms_number]?.rivers[0] : null
        setRiverParameter(riverParameter)
    }

    const changeRiverArea = (event) => {
        const slug = event.target.value
        const raId = props.config.slugs[slug].id
        setRiverAreaId(raId)
        setRiverAreaSlug(slug)
        setRiverParameter(null)
        setMsSlug(null)
    };

    const changeRiver = (event) => {
        setRiverParameter(event.target.value)
        setMsSlug(null);
    };

    const changeMeasurementSite = (event) => {
        setMsSlug(event.target.value)
    };

    const openDetail = () => {
        if (msSlug) {
            history.push('/flussgebiet/' + riverAreaSlug + '/' + msSlug)
        } else if (riverParameter) {
            const river = props.config?.riverareas[riverAreaId].rivers[riverParameter]
            if (!river) {
                console.error("River not found");
                return
            }
            history.push('/flussgebiet/' + riverAreaSlug + '#' + river.name)
        }
        else {
            history.push('/flussgebiet/' + riverAreaSlug)
        }
    }

    useEffect(() => {
        setActiveSelectFieldsAndRivers();
    }, [props.riverAreaSlug, props.msSlug])

    return (
        <div className='m-detail-menu l-content-box'>
            <Button variant="contained" href={'/'} className="button">Zurück zur Kartenansicht</Button>

            <p className="h4">Datenansicht <span>wählen<EHelpIcon hash={'navigation'} label="Erläuterungen zur Navigation in der Datenansicht"/></span></p>

            <FormControl fullWidth>
                <InputLabel aria-label="Select Option zum Auswählen der Flussgebiete.">Flussgebiet</InputLabel>
                <NativeSelect
                    defaultValue={riverAreaSlug}
                    onChange={changeRiverArea}
                    className="m-detail-menu__select"
                >
                    {props.config?.riverareas && Object.keys(props.config.riverareas).map((raId, i) => {
                        const riverArea = props.config.riverareas[raId]
                        return (
                            <option value={riverArea.slug} key={i}>{riverArea.name}</option>
                        )
                    })}
                </NativeSelect>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel shrink={true} aria-label="Select Option zum Auswählen der Flüsse.">Fluss</InputLabel>
                <NativeSelect
                    value={riverParameter || ''}
                    onChange={changeRiver}
                    className="m-detail-menu__select"
                >
                    {!riverParameter && <option value="" key={-1}>Fluss auswählen</option>}
                    {riverAreaId && Object.keys(props.config?.riverareas[riverAreaId].rivers).map(riverParameter => {
                        const river = props.config?.riverareas[riverAreaId].rivers[riverParameter]
                        return (
                            <option value={riverParameter} key={riverParameter}>{river.name}</option>
                        )
                    })}
                </NativeSelect>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel shrink={true} aria-label="Select Option zum Auswählen der Pegelorte.">Pegelort</InputLabel>
                <NativeSelect
                    value={msSlug || ''}
                    onChange={changeMeasurementSite}
                    className="m-detail-menu__select"
                >
                    {!msSlug && <option value="" key={-1}>Pegelort auswählen</option>}
                    {riverAreaId && riverParameter && props.config?.riverareas[riverAreaId].rivers[riverParameter].measurementSites.map(msNumber => {
                        return (
                            <option value={props.config?.measurementsite[msNumber].slug} key={msNumber}>{props.config?.measurementsite[msNumber].name}</option>
                        )
                    })}
                </NativeSelect>
            </FormControl>

            <Stack direction="row" justifyContent="flex-end" className="m-detail-menu__submit-wrapper">
                <Button variant="contained" className="button" onClick={openDetail}>anzeigen</Button>
            </Stack>
        </div>
    )
}

export default MDetailMenu