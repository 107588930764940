const routes = {
  accessibility: {
    path: "/barrierefreiheit",
    label: "Barrierefreiheit",
  },
  contact: {
    path: "/kontakt",
    label: "Kontakt",
  },
  faq: {
    path: "/faq",
    label: "FAQ",
  },
  help: {
    path: "/erlaeuterungen",
    label: "Erläuterungen",
  },
  helpEarlyAlerts: {
    path: "/erlaeuterungen-fruehwarnung",
    label: "Erläuterungen-Vorwarnung",
  },
  home: {
    path: "/",
    label: "Startseite",
  },
  imprint: {
    path: "/impressum",
    label: "Impressum",
  },
  levelList: {
    path: "/pegelliste",
    label: "Pegelliste",
  },
  levelReportingMarks: {
    path: "/meldemarken",
    label: "Meldehöhen",
  },
  links: {
    path: "/links",
    label: "Links",
  },
  news: {
    path: "/hinweise",
    label: "Aktuelles",
  },
  privacyPolicy: {
    path: "/datenschutz",
    label: "Datenschutz",
  },
  publications: {
    path: "/publikationen",
    label: "Publikationen",
  },
  rainfall: {
    path: "/niederschlagskarte",
    label: "Niederschlag",
  },
  "rainfall-mosel": {
    path: "/niederschlagskarte#mosel",
    label: "Moselgebiet",
  },
  "rainfall-rlp": {
    path: "/niederschlagskarte#rheinland-pfalz",
    label: "Rheinland-Pfalz",
  },
  sources: {
    path: "/informationswege",
    label: "Informationswege",
  },
  statusReport: {
    path: "/hochwasserbericht",
    label: "Hochwasserbericht",
  },
  weatherReport: {
    path: "/wetterbericht",
    label: "Wetterbericht",
  },
  warnings: {
    path: "/warnungen",
    label: "Warnungen",
  },
};

export default routes;
