import { Interaction } from 'chart.js';
import moment from "moment-timezone";

/**
 * function for checking if array exists
 */
export const validateArray = (data: Array<any>) => {
    if (data && data.length) {
        return data
    } else {
        return []
    }
}

/**
 * function for validating the existence of a timestamp
 */
export let validateTimestamp = (timestamp: string): string => {
    if (timestamp) {
        return timestamp
    } else {
        return ""
    }
}

export let validateObject = (obj: Record<string, any>): Record<string, any> => {
    if(obj){
        return obj
    } else {
        return {}
    }
}

export const momentParse = (date: string|moment.Moment|null): moment.Moment|null => {
    return date ? moment.utc(date).tz('Europe/Berlin') : null
}

export const momentFormatYear = (date: string|moment.Moment|null): string|null => {
    return momentParse(date)?.format('YYYY')
}

export const momentFormatDayOfMonth = (date: string|moment.Moment|null): string|null => {
    return momentParse(date)?.format('DD.MM.')
}

export const momentFormatDate = (date: string|moment.Moment|null): string|null => {
    return momentParse(date)?.format('DD.MM.YYYY')
}

export const momentFormatHour = (date: string|moment.Moment|null): string|null => {
    return momentParse(date)?.format('HH:mm')
}

export const momentFullFormat = (date: string|moment.Moment|null): string|null => {
    return momentParse(date)?.format('DD.MM.YYYY HH:mm')
}

export const formatNumber = (n: number, digits: number = 0) => {
    return new Intl.NumberFormat('de-DE', {minimumFractionDigits: digits}).format(n);
}

/**
 * Function for inverting Colors and get Contrast,
 * @params hex: hex-color, bw: boolean (true if color should be black or white)
 */
export const invertColor = (hex: string, bw: boolean)  => {
    if(hex?.length){
        if (hex?.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex?.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex?.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        var r = parseInt(hex.slice(0, 2), 16),
            g = parseInt(hex.slice(2, 4), 16),
            b = parseInt(hex.slice(4, 6), 16);
        if (bw) {
            return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                ? '#000000'
                : '#FFFFFF';
        }

        var rr = (255 - r).toString(16);
        var gg = (255 - g).toString(16);
        var bb = (255 - b).toString(16);

        return "#" + padZero(rr, '0') + padZero(gg, '0') + padZero(bb, '0');
    } else {
        return '#FFFFFF'
    }

}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

/**
 * Define `nearestOffset` tooltip interaction mode to allow showing tooltips only if points exist within defined offset.
 */
declare module 'chart.js' {
    interface InteractionModeMap {
        nearestOffset: InteractionModeFunction;
    }
}
Interaction.modes.nearestOffset = (chart, e, options, useFinalPosition) => {
    const items = Interaction.modes.nearest(chart, e, options, useFinalPosition)
    const offset = (options as any).nearestOffset
    if (!items.length || (items.length === 1 && items[0].datasetIndex === 0) || (typeof offset !== 'number')) {
        return items
    }

    return items.filter((item) => Math.floor(Math.abs(item.element.x - e.x)) <= offset)
}
