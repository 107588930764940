import { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import globalService from '../../../service/globalService';
import "./VStaticPage.scss";

interface IProps {
    route: string
    path?: string
}

const VStaticPage = (props: IProps) => {

    const [innerHTML, setInnerHTML] = useState(null)
    const locationHash = useLocation().hash;

    const getInnerHTML = useCallback(async () => {
        const res = await globalService.getPartial(props.route, props.path)
        if (res) {
            setInnerHTML(res)
        }
    }, [props.route, props.path])

    // Load partial as soon as component is loaded.
    useEffect(() => {
        getInnerHTML()
    }, [props.route, props.path, getInnerHTML])

    // Scroll to anchor if isset and partial is loaded.
    useEffect(() => {
        if (innerHTML && locationHash !== '') {
            // window.scrollTo(0, 0)

            // setTimeout(() => {
                const hash = locationHash.slice(1)
                // Handle direct hash links to element ids.
                let anchor = document.getElementById(hash)
                if (anchor) {
                    // Because element margin is hard to calculate, we use previous element for scroll offset calculation.
                    const prevSibling = anchor.previousElementSibling
                    if (prevSibling) {
                        const anchorBounds = anchor.getBoundingClientRect()
                        const prevBounds = prevSibling.getBoundingClientRect()
                        window.scrollTo(0, anchor.offsetTop - (anchorBounds.top - prevBounds.bottom))
                    }
                }
                // Handle hash links to named anchors.
                else {
                    anchor = document.getElementsByName(hash)[0]
                    if (anchor) {
                        window.scrollTo(0, anchor.offsetTop)
                    }
                }
            // }, 100)
        }
    }, [innerHTML, locationHash])

    return (
        <div className="container static-page">
            <div className="v-static-page" style={{ padding: '1rem 0' }}>
                {innerHTML &&
                    <div dangerouslySetInnerHTML={{ __html: innerHTML }}/>
                }
            </div>
        </div>
    )
}

export default VStaticPage;