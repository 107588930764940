import axios, {AxiosInstance, AxiosResponse} from 'axios';
import errorHandler from "./errorHandler";
import apiPaths from "../config/apiPaths";

/**
 * Create a new axios instance with default configuration for all requests
 */
const apiClient: AxiosInstance = axios.create({
    baseURL: apiPaths.base,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default apiClient

/**
 * Validate server response
 */
export const validateResponse = (res: AxiosResponse) => {
    const responseData = res.data;
    if(!responseData || responseData.error) {
        // TODO: adapt to api error handling
        return errorHandler(responseData)
    }
    else return responseData
}
