const isValidString = (name: string): boolean => {
    if (name === '' || typeof name !== 'string') {
        console.error(`invalid name "${name}" given to storage service`)
        return false
    }
    return true
}

/**
 * modify the type of accepted strings when local storage instances are added or modified
 */
export type TLocalStorageName = 'alertRegions' | 'riverLines' | 'stations' | 'riverAreas' | 'lakes' | 'neighbourcountry'
export type TLocalStorageHashName = 'alertRegionsHash' | 'riverLinesHash' | 'stationsHash' | 'riverAreasHash' | 'lakesHash' | 'neighbourcountryHash'

/**
 * Creates a local storage service to deal with JSON values and specify its accepted types
 */
export const localService = {
    get: (name: TLocalStorageName | TLocalStorageHashName): any => {
        if (isValidString(name)) {
            try {
                const res = localStorage.getItem(name);
                if (res) return JSON.parse(res)
            } catch (err) {
                console.warn(err)
            }
        }
        return undefined
    },
    set: (name: TLocalStorageName | TLocalStorageHashName, value: any): void => {
        if (isValidString(name)) {
            try {
                localStorage.setItem(name, JSON.stringify(value))
            } catch (err) {
                console.warn(err)
            }
        }
    },
    add: (name: TLocalStorageName | TLocalStorageHashName, newValue: any): void => {
        const currentValue: any = localService.get(name)
        const res = Array.isArray(currentValue) ? currentValue : []
        if (!res.includes(newValue)) res.push(newValue)
        try {
            localService.set(name, res)
        } catch (err) {
            console.warn(err)
        }
    },
    remove: (name: TLocalStorageName | TLocalStorageHashName): void => {
        if (isValidString(name)) {
            try {
                localStorage.removeItem(name)
            } catch (err) {
                console.warn(err)
            }
        }
    },
    clear: (): void => {
        try {
            localStorage.clear();
        } catch (err) {
            console.warn(err)
        }
    }
}
