import axios, { AxiosError } from "axios";
import store from "../store/store";

/**
 * Globally handles wanted or unwanted network errors.
 */
const errorHandler = (err: AxiosError | any) => {
    if (axios.isAxiosError(err)) {
        /**
         * Handle Error Response Codes
         */
        // TODO: adapt to api error handling
    } else {
        /**
         * Handle unknown network errors
         */
        console.warn('No valid server response', err);
    }
    store.getActions().notify.set({
        shown: true,
        message: err.message ? err.message : 'No valid server response',
        severity: 'error',
    });
    return err;
};

export default errorHandler;
