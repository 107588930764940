import store from "../store/store";
import axios from "axios";
import { axiosETAGCache } from "axios-etag-cache";

let interval: NodeJS.Timeout = null;
const DWD_REFRESH_INTERVAL = 5 * 60 * 1000;
const instance = axios.create();

/**
 * fetches image as base64 and stores it in store
 */
export const fetchCachedDwdImage = async (imgSrc) => {
  const axiosWithETAGCache = axiosETAGCache(instance);
  const r = await axiosWithETAGCache.get(imgSrc, {
    responseType: "arraybuffer",
  });
  const image = btoa(
    new Uint8Array(r.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );
  const imageBase64 = `data:${r.headers[
    "content-type"
  ].toLowerCase()};base64,${image}`;
  store.getActions().dwdUrl.update(imageBase64);
};

export const initRefreshService = (imgSrc) => {
  //prevent multiple intervals running simultanously
  if (interval) {
    return;
  }

  if (!imgSrc) {
    console.error('initRefreshService failed, no imgSrc provided');
    return;
  }

  interval = setInterval(() => {
    const isTabVisible = store.getState().tabVisible;
    if (isTabVisible) {
      fetchCachedDwdImage(imgSrc);
    }
  }, DWD_REFRESH_INTERVAL);
};

export const destroyRefreshService = () => {
  if (interval) {
    clearInterval(interval);
  }
};
