import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {useStoreState, useStoreActions} from 'easy-peasy';
import {IState, INotify} from "../../../store/store";

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ESnackbar = () => {

    const notify = useStoreState((state: IState) => state.notify.item);

    // @ts-ignore
    const clearNotify = useStoreActions(actions => actions.notify.set)

    const notifyObj: INotify = {
        shown: false,
        message: '',
        severity: 'error'
    }

    const handleClick = () => {
        clearNotify(notifyObj)
    }

    if (notify.message) {
        return (
            <Snackbar data-testid="snackbar" open={notify.shown} autoHideDuration={6000}>
                <Alert data-testid="snackbar-alert" onClose={handleClick} severity={notify.severity}>
                    {notify.message}
                </Alert>
            </Snackbar>
        )
    } else return null
}

export default ESnackbar