import './LBreadcrumbBar.scss';
import {useEffect, useState} from "react"
import {Link, useLocation} from "react-router-dom";
import routes from "../../../config/routes"
import IConfig from "../../../models/config";
import globalService from '../../../service/globalService';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

interface IProps {
}

const LBreadcrumbBar = (props: IProps) => {

    const [config, setConfig] = useState<IConfig>(null)
    const [navItems, setNavItems] = useState([])
    const location = useLocation()

    /**
     * Config-request to configure directlinks in main-menu
     */
    const getConfig = async () => {
        const config = await globalService.getConfig()
        if (config) {
            setConfig(config)
        }
    }

    useEffect(() => {
        getConfig()
    }, [])

    useEffect(() => {
        if (config) {
            // Determine breadcrumb links for current page.
            const navItems = []
            const segments = location.pathname.split('/')
            // Remove root element.
            segments.shift()

            const root = segments.shift()
            if (root) {
                // Handle river areas.
                if (root === 'flussgebiet') {
                    const raSlug = segments.shift()
                    const riverArea = config.slugs[raSlug]
                    if (riverArea) {
                        navItems.push({
                            label: riverArea.name,
                            path: '/flussgebiet/' + raSlug
                        })
                        // Handle measurement sites.
                        if (segments.length) {
                            const msSlug = segments.shift()
                            const measurementSite = riverArea.measurementsites[msSlug]
                            if (measurementSite) {
                                navItems.push({
                                    label: measurementSite.name,
                                    path: '/flussgebiet/' + raSlug + '/' + msSlug
                                })
                            }
                        }
                    }
                }
                // Handle other pages.
                else {
                    const route = Object.keys(routes).find(route => routes[route].path === location.pathname)
                    if (route) {
                        navItems.push(routes[route])
                    }
                }
            }
            setNavItems(navItems)
        }
    }, [location, config])

    return (
        <div className="l-breadcrumb-bar">
            <div className="container">
                <div className="l-breadcrumb-bar__inner">
                        <Link className="path-home" tabIndex={1} to={routes.home.path}>{routes.home.label}</Link>
                        {navItems.map((item, i) => (
                            <span className="path-wrapper" key={i}>
                                <ArrowRightIcon className="path-divider"/>
                                {i === navItems.length - 1 ? (
                                    <span className="path-current">{item.label}</span>
                                ) : (
                                    <Link className="path-link" tabIndex={1} to={item.path}>{item.label}</Link>
                                )}
                            </span>
                        ))}
                    </div>

            </div>
        </div>
    )
}

export default LBreadcrumbBar;
