import React from "react";
import {Link} from "react-router-dom";
import "./V404.scss";
import routes from "../../../config/routes";


const V404 = () => {
    return (
        <div className="page-not-exist">
            <h1>Diese Seite konnte nicht gefunden werden.</h1>
            <span>Zurück zur <Link to={routes.home.path}> Startseite </Link>.</span>
        </div>
    )
}

export default V404