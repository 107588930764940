import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { ILegend } from "../../../models/config";
import { slug } from "../../../utils/helpers";
import "./ELegend.scss";

export interface IToggleableLegend extends ILegend {
    active?: boolean
}

interface IProps {
    legends: Record<string, IToggleableLegend>;
    onActiveToggle?: (id: string, item: IToggleableLegend) => void
}

const ELegend = (props: IProps) => {

    const [legends, setLegends] = useState<Record<string, IToggleableLegend>>(props.legends)

    useEffect(() => {
        setLegends(props.legends)
    }, [props.legends])

    const toggleActive = (id: string, item: IToggleableLegend) => {
        item.active = !item.active
        props.onActiveToggle && props.onActiveToggle(id, item)
        setLegends({...legends})
    }

    const sortedLegendEntries = Object.entries(legends).sort((a, b) => {
        return a[1].sorting - b[1].sorting
    });

    return sortedLegendEntries.length ? (
        <div className="e-legend">
            {sortedLegendEntries.map(([legendId, legend]) => {
                const active = typeof(legend.active) === 'undefined' || legend.active
                return !legend.hiddenDetail && (
                    <label
                        key={legendId}
                        className={`e-legend__legends-row ${active ? 'e-legend__legends-row__active' : ''}`}
                        htmlFor={"legend-checkbox-" + slug(legendId)}
                    >
                        <Checkbox
                            checked={active}
                            aria-label="checkbox"
                            id={"legend-checkbox-" + slug(legendId)}
                            onChange={() => toggleActive(legendId, legend)}
                        />
                        <span className="e-legend__legend-line-wrapper">
                            <span
                                style={['hsw1', 'hsw2'].includes(legendId) ? {} : { backgroundColor: legend.color }}
                                className={'e-legend__legend-line ' + (['hsw1', 'hsw2'].includes(legendId) ? 'marine-value' : '')}
                            ></span>
                        </span>
                        <span className="e-legend__color-label">{ legend.description }</span>
                    </label>
                )
            })}
        </div>
    ) : null
}

export default ELegend
