import { TextField } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowUp";
import { Autocomplete } from "@material-ui/lab";
import { Link, useHistory } from "react-router-dom";
import IConfig, { ILegend } from "../../../models/config";
import EHelpIcon from "../../elements/help-icon/EHelpIcon";
import "./MInfobox.scss";
import { useEffect, useMemo, useState } from "react";

interface IProps {
    config: IConfig;
    showAlertRegions: boolean;
    setShowAlertRegions: Function;
    showRivers: boolean;
    setShowRivers: Function;
    showStations: boolean;
    setShowStations: Function;
    infoboxOpen: boolean;
    setInfoboxOpen: (isOpen: boolean) => void;
}

const MInfobox = (props: IProps) => {

    const history = useHistory()

    const legends: Array<ILegend> = useMemo(() => {
        const data = []
        for (let legend of Object.values(props.config?.legends?.W)) {
            data.push(legend)
        }
        data.sort((a, b) => a.sorting - b.sorting)
        return data
    }, [props.config]);
    
    const options = useMemo(() => {
        const options = []

        // Prepare autocomplete options and river area direct links.
        const riverAreas = props.config?.riverareas
        const measurementSites = props.config?.measurementsite
        const riverAreaIds = []
        if (riverAreas && measurementSites) {
            Object.keys(measurementSites).forEach((msNumber) => {
                const raId = measurementSites[msNumber].riverAreas[0]
                const riverArea = riverAreas[raId]

                // Check if river area has already been processed.
                if (riverAreaIds.indexOf(raId) === -1) {
                    riverAreaIds.push(raId)

                    options.push({
                        name: riverArea.name,
                        route: ('/flussgebiet/' + riverArea.slug)
                    })
                }

                options.push({
                    name: measurementSites[msNumber].name,
                    route: '/flussgebiet/' + riverArea.slug + '/' + measurementSites[msNumber].slug
                })
            })

            // Sort options alphabetically.
            options.sort((a, b) => a.name.localeCompare(b.name))
        }
        return options
    }, [props.config]);

    const toggleOpenState = () => {
        props.setInfoboxOpen(!props.infoboxOpen)
    }

    const rlpPathDefinition = "M88.4,1.11l11.05,15.7,.44,8.62-5.97,1.77-1.33,7.3,6.41,8.62-14.81,14.15,4.64,5.53,15.03-3.32,9.73,17.02-5.31,5.53,5.31,25.2-10.83,20.78-37.36-13.26-2.43-4.86,4.86-8.18-6.19-4.42,.88-9.73-9.95-3.98-18.13,7.52-9.28-2.65,6.63-16.36-8.62-2.43-8.18-14.37,3.32-11.5,10.39-5.08-1.55-3.98,17.24,1.99-2.21-7.3,5.08,1.11,2.65-6.41,27.41-11.27,7.3-6.63-.88-4.64,4.64-.44";

    return (
        <div className={"m-infobox" + (props.infoboxOpen ? " m-infobox--open" : "")}>
            <div className="m-infobox__head">
                <h3 className="m-infobox__heading">Details</h3>
                <div className="m-infobox__toggle-button" tabIndex={1} onClick={toggleOpenState} onKeyPress={toggleOpenState}>
                    <KeyboardArrowDownIcon aria-label="Klicke zum Aufklappen"/>
                </div>
            </div>

            <div className="m-infobox__body">
                {/* TODO: Refactor if all requirement conditions are met. See HMD-514
                   <div className="m-infobox__checkbox-wrap">
                    <div className="m-infobox__checkbox-label-wrap">
                        <FormControlLabel
                            className="m-infobox__checkbox-label"
                            control={
                                <Checkbox
                                    checked={props.showAlertRegions}
                                    onChange={(e) => {
                                        props.setShowAlertRegions(e.target.checked)
                                    }}
                                    color="default"
                                />
                            }
                            label="Warnregionen"
                            labelPlacement="end"
                        />
                    </div>

                    <div className="m-infobox__checkbox-label-wrap">
                        <FormControlLabel
                            className="m-infobox__checkbox-label"
                            value={props.showRivers}
                            control={
                                <Checkbox
                                    checked={props.showRivers}
                                    onChange={(e) => {
                                        props.setShowRivers(e.target.checked)
                                    }}
                                    color="default"
                                />
                            }
                            label="Flüsse"
                            labelPlacement="end"
                        />
                    </div>

                    <div className="m-infobox__checkbox-label-wrap">
                        <FormControlLabel
                            className="m-infobox__checkbox-label"
                            value={props.showRivers}
                            control={
                                <Checkbox
                                    checked={props.showStations}
                                    onChange={(e) => {
                                        props.setShowStations(e.target.checked)
                                    }}
                                    color="default"
                                />
                            }
                            label="Pegelorte"
                            labelPlacement="end"
                        />
                    </div>
                </div> */}
                <div className="m-infobox__search-wrap">
                    <h3 className="m-infobox__heading">Schnellauswahl<EHelpIcon hash={'schnell-navigation'} label="Hinweise zur schnellen Navigation"/></h3>
                    <div className="search">
                        <Autocomplete
                            disablePortal
                            options={options}
                            noOptionsText="Kein Ergebnis"
                            getOptionLabel={(options) => options.name || ""}
                            onChange={(_, newValue: {name: string, route: string}) => {
                                history.push(newValue.route)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="search__input"
                                    placeholder="Flussgebiet oder Pegel suchen"
                                    {...params}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="m-infobox__links-wrap">
                    {props.config?.riverareas && Object.keys(props.config.riverareas).map((el, index) => (
                        <Link to={'/flussgebiet/' + props.config.riverareas[el].slug} key={index} className="m-infobox__link">{props.config.riverareas[el].name}</Link>
                    ))}
                </div>

                <div className="m-infobox__legends-wrap">
                    <h3 className="m-infobox__heading">Legende<EHelpIcon hash={'warnkarte-legende'} label="Hinweise zur Legendenwarnkarte"/></h3>
                    <div className="m-infobox__legends">
                        <div className="m-infobox__legend-row ">
                            <div className="m-infobox__legend-headings">
                                <h4 className="m-infobox__legend-heading">Aktueller Messwert</h4>
                                <span className="m-infobox__legend-heading-subline">am Pegel</span>
                            </div>
                            <div className="m-infobox__legend-headings"></div>
                            <div className="m-infobox__legend-headings">
                                <h4 className="m-infobox__legend-heading">24h-Vorhersage</h4>
                                <span className="m-infobox__legend-heading-subline">für Warnregion</span>
                            </div>
                        </div>

                        {legends.length && legends.map((legend: ILegend, i: number) => (
                            <div key={i} className="m-infobox__legend-row">
                                <div className="m-infobox__legend-color">
                                    <span className="m-infobox__color-circle" style={{background: legend.color}}></span>
                                </div>
                                <div className="m-infobox__legend-label">{legend.name}</div>
                                {legend.alertClassId &&
                                    <div className="m-infobox__legend-alert-class">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 128 128">
                                            <g>
                                                <path
                                                    style={{fill:props.config.alertclasses[legend.alertClassId].color}}
                                                    d={rlpPathDefinition}
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                }
                            </div>
                        ))}

                        <div className="m-infobox__legend-row">
                            <div className="m-infobox__legend-color">
                                <span className="m-infobox__color-circle m-infobox__circle-prediction" style={{background: "#fff"}}></span>
                            </div>
                            <div className="m-infobox__legend-label">Vorhersage vorhanden</div>
                        </div>

                        <div className="m-infobox__legend-row">
                            <div className="m-infobox__legend-headings">
                                <h4>Vorwarnung</h4>
                            </div>

                            <div className="m-infobox__legend-label" />

                            <div className="m-infobox__legend-alert-class">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 128 128">
                                    <defs>
                                        <clipPath id="rlp-shape">
                                            <path d={rlpPathDefinition} />
                                        </clipPath>
                                    </defs>
                                    <g>
                                        <path
                                            style={{fill:props.config.alertclasses[2].color}}
                                            d={rlpPathDefinition}
                                        />
                                    </g>
                                    <g clipPath="url(#rlp-shape)">
                                        <rect
                                            className="m-infobox__legend-prealert-overlay"
                                            x="0" y="0"
                                            width="128" height="128"
                                            fill="url(#Hatch_2)"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MInfobox;