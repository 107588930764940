import { CircleMarker, GeoJSONProps, Tooltip, useMapEvents } from "react-leaflet"
import L from "leaflet"
import { checkTouchDevice, getIsSmallScreenSize, unproject, zoomToPointRadius } from "../../../utils/mapUtils"
import { memo, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import IConfig from '../../../models/config';
import IIndex from '../../../models';
import PredictionMarker from '../marker/PredictionMarker';
import {
    EMap__IMeasurementSitePreview as IMeasurementSitePreview,
    EMap__MeasurementSitePreviewContent as MeasurementSitePreviewContent
} from './EMap__MeasurementSitePreview';
import { useQuery } from "../../../utils/hooks";

const unprojectMeasurementSite = (coords, eastingOffset: number, northingOffset: number) => {
    // Modify point coordinates for easting or northin offset.
    if (eastingOffset || northingOffset) {
        // Copy coordinates to keep origanal data untouched, thus preventing comulative coordinate changes during rerender.
        coords = [...coords];
        if (eastingOffset) {
            coords[0] += eastingOffset;
        }
        if (northingOffset) {
            coords[1] += northingOffset;
        }
    }
    return unproject(coords);
}

const getPredictionColor = (hexColor: string): 'black' | 'white' => {
    const cleanedHex = hexColor.startsWith('#') ? hexColor.slice(1) : hexColor;

    const R = parseInt(cleanedHex.slice(0, 2), 16);
    const G = parseInt(cleanedHex.slice(2, 4), 16);
    const B = parseInt(cleanedHex.slice(4, 6), 16);

    const gamma = 2.2;

    const luminance = (
        0.2126 * Math.pow(R / 255, gamma) +
        0.7152 * Math.pow(G / 255, gamma) +
        0.0722 * Math.pow(B / 255, gamma)
    );

    return luminance > Math.pow(0.5, gamma) ? 'black' : 'white';
}

/**
 * Point Component Leaflet Layer
 * used to display and handle stations
 */
interface IProps {
    stations: (GeoJSONProps['data'] & { properties: any, geometry: any })[]
    config: IConfig
    index: IIndex
    setMobilePreview
}

const EMap__MeasurementSiteMarkers = (props: IProps) => {
    const { stations, config, index, setMobilePreview } = props

    const history = useHistory()
    const leafletMarkers = useRef({})
    const predictionMarkers = useRef({})
    const isTouchDevice = checkTouchDevice()

    const query = useQuery()

    // Create markers once.
    const markers = useMemo<L.CircleMarker[]>(() => {
        // Clear all leaflet marker instances referenced during last run.
        leafletMarkers.current = {}
        predictionMarkers.current = {}

        const result = []
        stations.forEach((station) => {
            const msNumber = station.properties.NUMBER
            const msConfig = config.measurementsite[msNumber]
            const msData = index.measurementSites[msNumber]

            if (msConfig && msData) {
                const riverAreaId: number | undefined = msConfig.riverAreas[0]
                const riverParameter: string | undefined = msConfig.rivers[0]
                const previewData: IMeasurementSitePreview = {
                    msConfig,
                    msData,
                    riverAreaSlug: config.riverareas[riverAreaId]?.slug,
                    riverName: config.rivers[riverParameter]?.name
                }
                const latLng = unprojectMeasurementSite(station.geometry.coordinates, msConfig.eastingOffset, msConfig.northingOffset)
                const hasPrediction = msConfig.hasPrediction;

                result.push(
                    <CircleMarker
                        center={latLng}
                        radius={getIsSmallScreenSize() ? 3 : 5}
                        fill={true}
                        fillColor={msData.legendColor || '#ffffff'}
                        fillOpacity={1}
                        color="black"
                        opacity={1}
                        weight={1}
                        key={msNumber}
                        ref={(ref) => {
                            if (ref) {
                                leafletMarkers.current[msNumber] = ref;
                            } else {
                                delete leafletMarkers.current[msNumber]
                            }
                        }}
                        eventHandlers={{
                            click: (e: any) => {
                                e.originalEvent._stopped = true;
                                if (isTouchDevice) {
                                    setMobilePreview(previewData)
                                } else {
                                    if (previewData.riverAreaSlug && msConfig.slug) {
                                        history.push('/flussgebiet/' + previewData.riverAreaSlug + '/' + msConfig.slug)
                                    }
                                }
                                return false
                            }
                        }}
                    >
                        {!isTouchDevice &&
                            <Tooltip opacity={1} className="e-map__tooltip">
                                <MeasurementSitePreviewContent {...previewData} />
                            </Tooltip>
                        }
                    </CircleMarker>
                )

                if (hasPrediction) {
                    const predictionColor = getPredictionColor(msData.legendColor)

                    result.push(
                        <PredictionMarker
                            center={latLng}
                            interactive={false}
                            radius={getIsSmallScreenSize() ? 3 : 5}
                            fill={true}
                            fillColor={predictionColor}
                            opacity={1}
                            key={msNumber + "pr"}
                            ref={(ref) => {
                                if (ref) {
                                    predictionMarkers.current[msNumber] = ref;
                                } else {
                                    delete predictionMarkers.current[msNumber]
                                }
                            }}

                        >
                        </PredictionMarker>
                    )
                }
            }

            if(msConfig && !msData) {
                const riverAreaId: number | undefined = msConfig.riverAreas[0]
                const riverParameter: string | undefined = msConfig.rivers[0]
                const previewData: IMeasurementSitePreview = {
                    msConfig,
                    riverAreaSlug: config.riverareas[riverAreaId]?.slug,
                    riverName: config.rivers[riverParameter]?.name
                }
                const latLng = unprojectMeasurementSite(station.geometry.coordinates, msConfig.eastingOffset, msConfig.northingOffset)
                const hasPrediction = msConfig.hasPrediction;

                result.push(
                    <CircleMarker
                        center={latLng}
                        radius={getIsSmallScreenSize() ? 3 : 5}
                        fill={true}
                        fillColor={'#ffffff'}
                        fillOpacity={1}
                        color="black"
                        opacity={1}
                        weight={1}
                        key={msNumber}
                        ref={(ref) => {
                            if (ref) {
                                leafletMarkers.current[msNumber] = ref;
                            } else {
                                delete leafletMarkers.current[msNumber]
                            }
                        }}
                    >
                        {!isTouchDevice &&
                            <Tooltip opacity={1} className="e-map__tooltip">
                                <MeasurementSitePreviewContent {...previewData} />
                            </Tooltip>
                        }
                    </CircleMarker>
                )

                if (hasPrediction) {
                    const predictionColor = getPredictionColor('#ffffff')

                    result.push(
                        <PredictionMarker
                            center={latLng}
                            interactive={false}
                            radius={getIsSmallScreenSize() ? 3 : 5}
                            fill={true}
                            fillColor={predictionColor}
                            opacity={1}
                            key={msNumber + "pr"}
                            ref={(ref) => {
                                if (ref) {
                                    predictionMarkers.current[msNumber] = ref;
                                } else {
                                    delete predictionMarkers.current[msNumber]
                                }
                            }}

                        >
                        </PredictionMarker>
                    )
                }
            }
        })

        return result
    }, [stations, config, index, setMobilePreview, isTouchDevice, history])

    useMapEvents({
        zoomend: (e) => {
            const radius = zoomToPointRadius(e.target._zoom)
            // Directly update marker radius on leaflet objects instead of using a state to improve map performance.
            for (const msNumber in leafletMarkers.current) {
                if (Object.prototype.hasOwnProperty.call(leafletMarkers.current, msNumber)) {
                    leafletMarkers.current[msNumber].setRadius(radius);
                }
                if (Object.prototype.hasOwnProperty.call(predictionMarkers.current, msNumber)) {
                    predictionMarkers.current[msNumber].setRadius(radius);
                }
            }
        }
    })

    return <>{markers}</>
}

export default EMap__MeasurementSiteMarkers

export const EMap__MemoizedMeasurementSiteMarkers = memo(EMap__MeasurementSiteMarkers)
